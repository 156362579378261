// Importación imágenes
import Logo from '../assets/statics/club_logos/capdepera_golf_logo.png'

export const courseData = {
    1: {
        gp: 470,
        g: 452,
        lp: 395,
        l: 384,
        par: 5,
        hcp: 8
    },
    2: {
        gp: 182,
        g: 173,
        lp: 150,
        l: 136,
        par: 3,
        hcp: 14
    },
    3: {
        gp: 512,
        g: 504,
        lp: 483,
        l: 470,
        par: 5,
        hcp: 2
    },
    4: {
        gp: 115,
        g: 108,
        lp: 101,
        l: 93,
        par: 3,
        hcp: 18
    },
    5: {
        gp: 346,
        g: 337,
        lp: 329,
        l: 318,
        par: 4,
        hcp: 12
    },
    6: {
        gp: 307,
        g: 298,
        lp: 279,
        l: 259,
        par: 4,
        hcp: 10
    },
    7: {
        gp: 212,
        g: 205,
        lp: 183,
        l: 171,
        par: 3,
        hcp: 4
    },
    8: {
        gp: 417,
        g: 387,
        lp: 361,
        l: 342,
        par: 5,
        hcp: 16
    },
    9: {
        gp: 346,
        g: 336,
        lp: 328,
        l: 319,
        par: 4,
        hcp: 6
    },
    10: {
        gp: 256,
        g: 237,
        lp: 227,
        l: 227,
        par: 4,
        hcp: 17
    },
    11: {
        gp: 208,
        g: 191,
        lp: 170,
        l: 115,
        par: 3,
        hcp: 5
    },
    12: {
        gp: 543,
        g: 532,
        lp: 461,
        l: 452,
        par: 5,
        hcp: 1
    },
    13: {
        gp: 311,
        g: 291,
        lp: 264,
        l: 247,
        par: 4,
        hcp: 13
    },
    14: {
        gp: 331,
        g: 318,
        lp: 293,
        l: 255,
        par: 4,
        hcp: 3
    },
    15: {
        gp: 181,
        g: 147,
        lp: 108,
        l: 102,
        par: 3,
        hcp: 11
    },
    16: { 
        gp: 360,
        g: 348,
        lp: 307,
        l: 297,
        par: 4,
        hcp: 15
    },
    17: {
        gp: 310,
        g: 297,
        lp: 250,
        l: 239,
        par: 4,
        hcp: 9
    },
    18: {
        gp: 440,
        g: 428,
        lp: 377,
        l: 347,
        par: 5,
        hcp: 7
    },
    19:{
        totalGp: 5847,
        totalG: 5589,
        totalLp: 5066,
        totalL: 4773,
        totalPar: "72 (36)",
    },
    20:{
        title: "Capdepera Golf",
        image: Logo
    }
}