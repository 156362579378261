// Importación imágenes
import Logo from '../assets/statics/club_logos/rotana_greens_logo.png'

export const courseData = {
    1: {
        gp: 458,
        g: 450,
        lp: 401,
        l: 373,
        par: 5,
        hcp: 11
    },
    2: {
        gp: 387,
        g: 368,
        lp: 350,
        l: 294,
        par: 4,
        hcp: 4
    },
    3: {
        gp: 335,
        g: 314,
        lp: 299,
        l: 280,
        par: 4,
        hcp: 12
    },
    4: {
        gp: 188,
        g: 160,
        lp: 132,
        l: 124,
        par: 3,
        hcp: 16
    },
    5: {
        gp: 376,
        g: 353,
        lp: 343,
        l: 323,
        par: 4,
        hcp: 7
    },
    6: {
        gp: 156,
        g: 137,
        lp: 127,
        l: 106,
        par: 3,
        hcp: 17
    },
    7: {
        gp: 579,
        g: 561,
        lp: 542,
        l: 523,
        par: 5,
        hcp: 3
    },
    8: {
        gp: 416,
        g: 398,
        lp: 365,
        l: 341,
        par: 4,
        hcp: 1
    },
    9: {
        gp: 397,
        g: 374,
        lp: 346,
        l: 324,
        par: 4,
        hcp: 5
    },
    10:{
        totalGp: 6499,
        totalG: 6152,
        totalLp: 5645,
        totalL: 5241,
        totalPar: "72 (36)",
    },
    11:{
        title: "Rotana Greens",
        image: Logo
    }
}