// Importación imágenes
import Logo from '../assets/statics/club_logos/golf_maioris_logo.png'

export const courseData = {
    1: {
        gp: 353,
        g: 337,
        lp: 322,
        l: 298,
        par: 4,
        hcp: 12
    },
    2: {
        gp: 384,
        g: 358,
        lp: 342,
        l: 318,
        par: 4,
        hcp: 8
    },
    3: {
        gp: 131,
        g: 125,
        lp: 118,
        l: 111,
        par: 3,
        hcp: 18
    },
    4: {
        gp: 457,
        g: 437,
        lp: 410,
        l: 390,
        par: 5,
        hcp: 2
    },
    5: {
        gp: 483,
        g: 461,
        lp: 415,
        l: 390,
        par: 5,
        hcp: 14
    },
    6: {
        gp: 371,
        g: 350,
        lp: 329,
        l: 306,
        par: 4,
        hcp: 6
    },
    7: {
        gp: 346,
        g: 331,
        lp: 309,
        l: 288,
        par: 4,
        hcp: 4
    },
    8: {
        gp: 208,
        g: 196,
        lp: 183,
        l: 172,
        par: 3,
        hcp: 16
    },
    9: {
        gp: 398,
        g: 360,
        lp: 343,
        l: 324,
        par: 4,
        hcp: 10
    },
    10: {
        gp: 537,
        g: 512,
        lp: 482,
        l: 451,
        par: 5,
        hcp: 1
    },
    11: {
        gp: 156,
        g: 149,
        lp: 141,
        l: 132,
        par: 3,
        hcp: 17
    },
    12: {
        gp: 344,
        g: 310,
        lp: 294,
        l: 278,
        par: 4,
        hcp: 9
    },
    13: {
        gp: 155,
        g: 142,
        lp: 116,
        l: 103,
        par: 3,
        hcp: 15
    },
    14: {
        gp: 479,
        g: 467,
        lp: 453,
        l: 442,
        par: 5,
        hcp: 13
    },
    15: {
        gp: 430,
        g: 421,
        lp: 365,
        l: 349,
        par: 4,
        hcp: 7
    },
    16: { 
        gp: 335,
        g: 318,
        lp: 296,
        l: 279,
        par: 4,
        hcp: 11
    },
    17: {
        gp: 387,
        g: 368,
        lp: 347,
        l: 328,
        par: 4,
        hcp: 5
    },
    18: {
        gp: 402,
        g: 384,
        lp: 360,
        l: 342,
        par: 4,
        hcp: 3
    },
    19:{
        totalGp: 6347,
        totalG: 6026,
        totalLp: 5625,
        totalL: 5301,
        totalPar: "72 (36)",
    },
    20:{
        title: "Golf Maioris",
        image: Logo
    }
}