// Importación imágenes
import Logo from '../assets/statics/club_logos/golf_santa_ponsa_logo.png'

export const courseData = {
    1: {
        gp: 433,
        g: 410,
        lp: 314,
        l: 314,
        par: 4,
        hcp: 4
    },
    2: {
        gp: 520,
        g: 500,
        lp: 439,
        l: 400,
        par: 5,
        hcp: 2
    },
    3: {
        gp: 160,
        g: 150,
        lp: 130,
        l: 130,
        par: 3,
        hcp: 18
    },
    4: {
        gp: 420,
        g: 400,
        lp: 370,
        l: 300,
        par: 4,
        hcp: 6
    },
    5: {
        gp: 310,
        g: 300,
        lp: 270,
        l: 270,
        par: 4,
        hcp: 14
    },
    6: {
        gp: 450,
        g: 440,
        lp: 400,
        l: 390,
        par: 5,
        hcp: 12
    },
    7: {
        gp: 170,
        g: 160,
        lp: 140,
        l: 140,
        par: 3,
        hcp: 16
    },
    8: {
        gp: 360,
        g: 350,
        lp: 310,
        l: 310,
        par: 4,
        hcp: 10
    },
    9: {
        gp: 400,
        g: 380,
        lp: 350,
        l: 350,
        par: 4,
        hcp: 8
    },
    10: {
        gp: 590,
        g: 560,
        lp: 530,
        l: 470,
        par: 5,
        hcp: 1
    },
    11: {
        gp: 360,
        g: 343,
        lp: 310,
        l: 219,
        par: 4,
        hcp: 15
    },
    12: {
        gp: 220,
        g: 200,
        lp: 170,
        l: 170,
        par: 3,
        hcp: 7
    },
    13: {
        gp: 460,
        g: 440,
        lp: 410,
        l: 390,
        par: 5,
        hcp: 11
    },
    14: {
        gp: 380,
        g: 350,
        lp: 330,
        l: 330,
        par: 4,
        hcp: 9
    },
    15: {
        gp: 170,
        g: 150,
        lp: 140,
        l: 140,
        par: 3,
        hcp: 17
    },
    16: { 
        gp: 400,
        g: 394,
        lp: 364,
        l: 337,
        par: 4,
        hcp: 3
    },
    17: {
        gp: 320,
        g: 300,
        lp: 280,
        l: 280,
        par: 4,
        hcp: 13
    },
    18: {
        gp: 420,
        g: 392,
        lp: 360,
        l: 310,
        par: 4,
        hcp: 5
    },
    19:{
        totalGp: 6543,
        totalG: 6219,
        totalLp: 5617,
        totalL: 4250,
        totalPar: "72 (36)",
    },
    20:{
        title: "Golf Santa Ponsa I",
        image: Logo
    }
}