// Importación imágenes
import Logo from '../assets/statics/club_logos/golf_park_logo.png'

export const courseData = {
    1: {
        gp: 342,
        g: 313,
        lp: 284,
        l: 242,
        par: 4,
        hcp: 7
    },
    2: {
        gp: 180,
        g: 155,
        lp: 124,
        l: 108,
        par: 3,
        hcp: 12
    },
    3: {
        gp: 376,
        g: 347,
        lp: 319,
        l: 289,
        par: 4,
        hcp: 3
    },
    4: {
        gp: 520,
        g: 494,
        lp: 462,
        l: 433,
        par: 5,
        hcp: 9
    },
    5: {
        gp: 364,
        g: 336,
        lp: 307,
        l: 285,
        par: 4,
        hcp: 1
    },
    6: {
        gp: 172,
        g: 139,
        lp: 139,
        l: 101,
        par: 3,
        hcp: 17
    },
    7: {
        gp: 314,
        g: 287,
        lp: 255,
        l: 224,
        par: 4,
        hcp: 13
    },
    8: {
        gp: 334,
        g: 310,
        lp: 289,
        l: 250,
        par: 4,
        hcp: 6
    },
    9: {
        gp: 339,
        g: 307,
        lp: 274,
        l: 252,
        par: 4,
        hcp: 14
    },
    10: {
        gp: 340,
        g: 316,
        lp: 288,
        l: 253,
        par: 4,
        hcp: 10
    },
    11: {
        gp: 467,
        g: 445,
        lp: 421,
        l: 385,
        par: 5,
        hcp: 11
    },
    12: {
        gp: 467,
        g: 445,
        lp: 421,
        l: 385,
        par: 5,
        hcp: 11
    },
    13: {
        gp: 169,
        g: 135,
        lp: 115,
        l: 92,
        par: 3,
        hcp: 18
    },
    14: {
        gp: 472,
        g: 442,
        lp: 409,
        l: 366,
        par: 5,
        hcp: 16
    },
    15: {
        gp: 369,
        g: 328,
        lp: 300,
        l: 280,
        par: 4,
        hcp: 2
    },
    16: { 
        gp: 172,
        g: 148,
        lp: 124,
        l: 99,
        par: 3,
        hcp: 8
    },
    17: {
        gp: 346,
        g: 330,
        lp: 304,
        l: 276,
        par: 4,
        hcp: 16
    },
    18: {
        gp: 379,
        g: 357,
        lp: 330,
        l: 297,
        par: 4,
        hcp: 4
    },
    19:{
        totalGp: 6047,
        totalG: 5546,
        totalLp: 5074,
        totalL: 4530,
        totalPar: "71 (36)",
    },
    20:{
        title: "Golf Park Mallorca",
        image: Logo
    }
}