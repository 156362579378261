import React from 'react';

// Importación estilos
import '../assets/styles/scorecard.css'
import "../assets/styles/button.css";

// Importación hooks
import downloadHook from "../hooks/downloadHook"

// Importación de componentes
import HeaderScoreCard from "./HeaderScoreCard";

const CourseScorecard9 = (props) => {

    React.useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
      
    const [input, setInput] = React.useState({
        playerName: sessionStorage.getItem('playerName') ? sessionStorage.getItem('playerName') : '',
        p1h1: sessionStorage.getItem('p1h1') ? sessionStorage.getItem('p1h1') : '',
        p1h2: sessionStorage.getItem('p1h2') ? sessionStorage.getItem('p1h2') : '',
        p1h3: sessionStorage.getItem('p1h3') ? sessionStorage.getItem('p1h3') : '',
        p1h4: sessionStorage.getItem('p1h4') ? sessionStorage.getItem('p1h4') : '',
        p1h5: sessionStorage.getItem('p1h5') ? sessionStorage.getItem('p1h5') : '',
        p1h6: sessionStorage.getItem('p1h6') ? sessionStorage.getItem('p1h6') : '',
        p1h7: sessionStorage.getItem('p1h7') ? sessionStorage.getItem('p1h7') : '',
        p1h8: sessionStorage.getItem('p1h8') ? sessionStorage.getItem('p1h8') : '',
        p1h9: sessionStorage.getItem('p1h9') ? sessionStorage.getItem('p1h9') : '',
    });

    const handleInput = function (e) {
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });
        sessionStorage.setItem(e.target.name, e.target.value);
    };

    const { 
        p1h1, 
        p1h2,
        p1h3,
        p1h4,
        p1h5,
        p1h6,
        p1h7,
        p1h8,
        p1h9,
    } = input;

    const result = Number(p1h1)
        + Number(p1h2)
        + Number(p1h3)
        + Number(p1h4)
        + Number(p1h5)
        + Number(p1h6)
        + Number(p1h7)
        + Number(p1h8)
        + Number(p1h9);
    
    const currentDate = new Date();
    const date = `${currentDate.getDate()}/${currentDate.getMonth()+1}/${currentDate.getFullYear()}`;
    return( 
        <div>
            <HeaderScoreCard />
            <div className="table-download" id="scorecard_table">
                <div className='intro-scorecard'>
                    <img className='title-scorecard__img' src={props.data[11].image} alt={props.data[11].title} />
                    <p className='title-scorecard'>{props.data[11].title}</p>
                </div> 
                <table className="customTable">
                                <thead>
                                    <tr>
                                        <th className="scorecard_title--standard">H.</th>
                                        <th className="scorecard_title--white">G.P</th>
                                        <th className="scorecard_title--yellow">G</th>
                                        <th className="scorecard_title--blue">L.P</th>
                                        <th className="scorecard_title--pink">L</th>
                                        <th className="scorecard_title--standard">Par</th>
                                        <th className="scorecard_title--standard">Hcp</th>
                                        <th className="scorecard_title--player">
                                            <input 
                                                className='edit_player'
                                                name= "playerName"  
                                                onChange={handleInput}
                                                value={input.playerName}
                                                placeholder="Nombre">
                                            </input>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="scorecard_input--hole">1</td>
                                        <td className="scorecard_input--white">{props.data[1].gp}</td>
                                        <td className="scorecard_input--yellow">{props.data[1].g}</td>
                                        <td className="scorecard_input--blue">{props.data[1].lp}</td>
                                        <td className="scorecard_input--pink">{props.data[1].l}</td>
                                        <td className="scorecard_input--standard">{props.data[1].par}</td>
                                        <td className="scorecard_input--standard">{props.data[1].hcp}</td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p1h1" 
                                                value={input.p1h1}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="scorecard_input--hole">2</td>
                                        <td className="scorecard_input--white">{props.data[2].gp}</td>
                                        <td className="scorecard_input--yellow">{props.data[2].g}</td>
                                        <td className="scorecard_input--blue">{props.data[2].lp}</td>
                                        <td className="scorecard_input--pink">{props.data[2].l}</td>
                                        <td className="scorecard_input--standard">{props.data[2].par}</td>
                                        <td className="scorecard_input--standard">{props.data[2].hcp}</td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p1h2" 
                                                value={input.p1h2}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="scorecard_input--hole">3</td>
                                        <td className="scorecard_input--white">{props.data[3].gp}</td>
                                        <td className="scorecard_input--yellow">{props.data[3].g}</td>
                                        <td className="scorecard_input--blue">{props.data[3].lp}</td>
                                        <td className="scorecard_input--pink">{props.data[3].l}</td>
                                        <td className="scorecard_input--standard">{props.data[3].par}</td>
                                        <td className="scorecard_input--standard">{props.data[3].hcp}</td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p1h3" 
                                                value={input.p1h3}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="scorecard_input--hole">4</td>
                                        <td className="scorecard_input--white">{props.data[4].gp}</td>
                                        <td className="scorecard_input--yellow">{props.data[4].g}</td>
                                        <td className="scorecard_input--blue">{props.data[4].lp}</td>
                                        <td className="scorecard_input--pink">{props.data[4].l}</td>
                                        <td className="scorecard_input--standard">{props.data[4].par}</td>
                                        <td className="scorecard_input--standard">{props.data[4].hcp}</td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p1h4" 
                                                value={input.p1h4}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="scorecard_input--hole">5</td>
                                        <td className="scorecard_input--white">{props.data[5].gp}</td>
                                        <td className="scorecard_input--yellow">{props.data[5].g}</td>
                                        <td className="scorecard_input--blue">{props.data[5].lp}</td>
                                        <td className="scorecard_input--pink">{props.data[5].l}</td>
                                        <td className="scorecard_input--standard">{props.data[5].par}</td>
                                        <td className="scorecard_input--standard">{props.data[5].hcp}</td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p1h5" 
                                                value={input.p1h5}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="scorecard_input--hole">6</td>
                                        <td className="scorecard_input--white">{props.data[6].gp}</td>
                                        <td className="scorecard_input--yellow">{props.data[6].g}</td>
                                        <td className="scorecard_input--blue">{props.data[6].lp}</td>
                                        <td className="scorecard_input--pink">{props.data[6].l}</td>
                                        <td className="scorecard_input--standard">{props.data[6].par}</td>
                                        <td className="scorecard_input--standard">{props.data[6].hcp}</td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p1h6" 
                                                value={input.p1h6}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="scorecard_input--hole">7</td>
                                        <td className="scorecard_input--white">{props.data[7].gp}</td>
                                        <td className="scorecard_input--yellow">{props.data[7].g}</td>
                                        <td className="scorecard_input--blue">{props.data[7].lp}</td>
                                        <td className="scorecard_input--pink">{props.data[7].l}</td>
                                        <td className="scorecard_input--standard">{props.data[7].par}</td>
                                        <td className="scorecard_input--standard">{props.data[7].hcp}</td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p1h7" 
                                                value={input.p1h7}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="scorecard_input--hole">8</td>
                                        <td className="scorecard_input--white">{props.data[8].gp}</td>
                                        <td className="scorecard_input--yellow">{props.data[8].g}</td>
                                        <td className="scorecard_input--blue">{props.data[8].lp}</td>
                                        <td className="scorecard_input--pink">{props.data[8].l}</td>
                                        <td className="scorecard_input--standard">{props.data[8].par}</td>
                                        <td className="scorecard_input--standard">{props.data[8].hcp}</td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p1h8" 
                                                value={input.p1h8}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="scorecard_input--hole">9</td>
                                        <td className="scorecard_input--white">{props.data[9].gp}</td>
                                        <td className="scorecard_input--yellow">{props.data[9].g}</td>
                                        <td className="scorecard_input--blue">{props.data[9].lp}</td>
                                        <td className="scorecard_input--pink">{props.data[9].l}</td>
                                        <td className="scorecard_input--standard">{props.data[9].par}</td>
                                        <td className="scorecard_input--standard">{props.data[9].hcp}</td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p1h9" 
                                                value={input.p1h9}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                    </tr>   
                                </tbody>
                                <tfoot>
                                    <tr>
                                    <td className="results_standard">Total</td>
                                        <td className="results_starts">{props.data[10].totalGp}</td>
                                        <td className="results_starts">{props.data[10].totalG}</td>
                                        <td className="results_starts">{props.data[10].totalLp}</td>
                                        <td className="results_starts">{props.data[10].totalL}</td>
                                        <td className="results_standard">{props.data[10].totalPar}</td>
                                        <td className="results_standard">-</td>
                                        <td className="results_player">{result}</td>
                                    </tr>
                                </tfoot>      
                    </table>
                    <p className='legend-scorecard' >Ha ahorrado <b>150g de CO2</b>. Fecha: {date}. Tecnología por <b>Mascaddie</b>.  </p>
                    </div>
                    <button className="button" onClick={downloadHook}>Guarde su tarjeta de puntuación</button>
            </div>
    )
};

export default CourseScorecard9;