// Importación imágenes
import Logo from '../assets/statics/club_logos/golf_alcanada_logo.png'

export const courseData = {
    1: {
        gp: 458,
        g: 450,
        lp: 401,
        l: 373,
        par: 5,
        hcp: 11
    },
    2: {
        gp: 387,
        g: 368,
        lp: 350,
        l: 294,
        par: 4,
        hcp: 4
    },
    3: {
        gp: 335,
        g: 314,
        lp: 299,
        l: 280,
        par: 4,
        hcp: 12
    },
    4: {
        gp: 188,
        g: 160,
        lp: 132,
        l: 124,
        par: 3,
        hcp: 16
    },
    5: {
        gp: 376,
        g: 353,
        lp: 343,
        l: 323,
        par: 4,
        hcp: 7
    },
    6: {
        gp: 156,
        g: 137,
        lp: 127,
        l: 106,
        par: 3,
        hcp: 17
    },
    7: {
        gp: 579,
        g: 561,
        lp: 542,
        l: 523,
        par: 5,
        hcp: 3
    },
    8: {
        gp: 416,
        g: 398,
        lp: 365,
        l: 341,
        par: 4,
        hcp: 1
    },
    9: {
        gp: 397,
        g: 374,
        lp: 346,
        l: 324,
        par: 4,
        hcp: 5
    },
    10: {
        gp: 363,
        g: 348,
        lp: 333,
        l: 307,
        par: 4,
        hcp: 10
    },
    11: {
        gp: 554,
        g: 435,
        lp: 516,
        l: 495,
        par: 5,
        hcp: 6
    },
    12: {
        gp: 347,
        g: 324,
        lp: 295,
        l: 280,
        par: 4,
        hcp: 8
    },
    13: {
        gp: 454,
        g: 451,
        lp: 424,
        l: 410,
        par: 5,
        hcp: 18
    },
    14: {
        gp: 167,
        g: 145,
        lp: 140,
        l: 114,
        par: 3,
        hcp: 14
    },
    15: {
        gp: 326,
        g: 297,
        lp: 264,
        l: 244,
        par: 4,
        hcp: 13
    },
    16: { 
        gp: 435,
        g: 418,
        lp: 335,
        l: 332,
        par: 4,
        hcp: 2
    },
    17: {
        gp: 213,
        g: 194,
        lp: 164,
        l: 157,
        par: 3,
        hcp: 9
    },
    18: {
        gp: 348,
        g: 325,
        lp: 304,
        l: 270,
        par: 4,
        hcp: 15
    },
    19:{
        totalGp: 6499,
        totalG: 6152,
        totalLp: 5645,
        totalL: 5241,
        totalPar: "72 (36)",
    },
    20:{
        title: "Golf Alcanada",
        image: Logo
    }
}