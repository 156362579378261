import React from 'react';
import { Link } from 'react-router-dom';

// Importación imágenes clubs backgrounds
import son_quint_club from '../assets/statics/club_backgrounds/son_quint_club.jpg'
import son_quint_pitchandputt_club from '../assets/statics/club_backgrounds/son_quint_pitch_and_putt_club.jpg'
import son_muntner_club from '../assets/statics/club_backgrounds/son_muntaner_club.jpg'
import son_vida_green_club from '../assets/statics/club_backgrounds/son_vida_green_club.jpg'
import son_vida_orange_club from '../assets/statics/club_backgrounds/son_vida_orange_club.jpg'
import golf_pollensa from '../assets/statics/club_backgrounds/golf_pollensa.jpg'
import golf_andratx from '../assets/statics/club_backgrounds/golf_andratx.jpg'
import real_golf_bendinat from '../assets/statics/club_backgrounds/real_golf_bendinat.jpg'
import golf_santa_ponsa_1 from '../assets/statics/club_backgrounds/golf_santa_ponsa_1.jpg'
import golf_santa_ponsa_2 from '../assets/statics/club_backgrounds/golf_santa_ponsa_2.jpg'
import golf_santa_ponsa_3 from '../assets/statics/club_backgrounds/golf_santa_ponsa_3.jpg'
import t_golf from '../assets/statics/club_backgrounds/t_golf.jpg'
import son_gual from '../assets/statics/club_backgrounds/son_gual.jpg'
import golf_park from '../assets/statics/club_backgrounds/golf_park.jpg'
import golf_maioris from '../assets/statics/club_backgrounds/golf_maioris.jpg'
import son_antem_east from '../assets/statics/club_backgrounds/son_antem_east.jpg'
import son_antem_west from '../assets/statics/club_backgrounds/son_antem_west.jpg'
import vall_dor_golf from '../assets/statics/club_backgrounds/vall_dor_golf.jpg'
import son_severa from '../assets/statics/club_backgrounds/son_severa.jpg'
import golf_alcanada from '../assets/statics/club_backgrounds/golf_alcanada.jpg'
import son_termes from '../assets/statics/club_backgrounds/son_termes.jpg'
import capdepera_golf from '../assets/statics/club_backgrounds/capdepera_golf.jpg'
import pula_mallorca from '../assets/statics/club_backgrounds/pula_mallorca.jpg'
import canyamel_golf from '../assets/statics/club_backgrounds/canyamel_golf.jpg'

// Importación logos clubs
import LogoSonMuntaner from '../assets/statics/club_logos/son_muntaner_logo.svg'
import LogoSonQuint from '../assets/statics/club_logos/son_quint_logo.svg'
import LogoSonQuintPP from '../assets/statics/club_logos/palma_pitch_and_putt_logo.svg'
import LogoSonVida from '../assets/statics/club_logos/son_vida_logo.svg'
import LogoGolfPollensa from '../assets/statics/club_logos/golf_pollensa_logo.png'
import LogoGolfAndratx from '../assets/statics/club_logos/golf_andratx_logo.png'
import LogoSantaPonsa1 from '../assets/statics/club_logos/golf_santa_ponsa_logo.png'
import LogoSantaPonsa2 from '../assets/statics/club_logos/golf_santa_ponsa_logo.png'
import LogoSantaPonsa3 from '../assets/statics/club_logos/golf_santa_ponsa_logo.png'
import LogoBendinat from '../assets/statics/club_logos/real_golf_bendinat.png'
import LogoTGolf from '../assets/statics/club_logos/t_golf_logo.png'
import LogoSonGual from '../assets/statics/club_logos/son_gual_logo.png'
import LogoPark from '../assets/statics/club_logos/golf_park_logo.png'
import LogoMaioris from '../assets/statics/club_logos/golf_maioris_logo.png'
import LogoSonantem from '../assets/statics/club_logos/son_antem_logo.png'
import LogovallDor from '../assets/statics/club_logos/vall_dor_golf_logo.png'
import LogoSonServera from '../assets/statics/club_logos/son_severa_logo.png'
import LogoAlcanada from '../assets/statics/club_logos/golf_alcanada_logo.png'
import LogoSonTermes from '../assets/statics/club_logos/son_termes_logo.png'
import LogoCapdepra from '../assets/statics/club_logos/capdepera_golf_logo.png'
import LogoPula from '../assets/statics/club_logos/pula_mallorca_logo.png'
import LogoCanyamel from '../assets/statics/club_logos/canyamel_golf_logo.png'


// Importación imágenes logo mascaddie
import mascaddie_logo from '../assets/statics/mascaddie_logo.png'

// Importación estilos
import '../assets/styles/courses.css'

const CourseSelection = () => {
    return (
        <div>
            <div className='title-courses'>
                <img className='title-courses__img' src={mascaddie_logo} alt='+Caddie logo'/>
            </div>
            <div className='club-list'>
                <div className='club-card' >
                    <Link to={'/sonmuntaner'} style={{ textDecoration: 'none' }}>
                        <img className='club-card__img' src={son_muntner_club} alt='Son Muntaner Golf Club' />
                        <div className='club-card__details'>
                            <img className='club-card__details--img' src={LogoSonMuntaner} alt='Son Muntaner Golf Club' />
                            <p className='club-card__details--title'>Son Muntaner Golf Club</p>
                            <p className='club-card__details--subtitle'>18 Hoyos | Palma de Mallorca, Mallorca.</p>
                        </div>
                    </Link>
                </div>
                <div className='club-card' >
                <Link to={'/sonquint'} style={{ textDecoration: 'none' }}>    
                        <img className='club-card__img' src={son_quint_club} alt='Son Quint Golf Club' />
                        <div className='club-card__details'>
                            <img className='club-card__details--img' src={LogoSonQuint} alt='Son Quint Golf Club logo' />
                            <p className='club-card__details--title'>Son Quint Golf Club</p>
                            <p className='club-card__details--subtitle'>18 Hoyos | Palma de Mallorca, Mallorca.</p>
                        </div>
                </Link>  
                </div>
                <div className='club-card' >
                <Link to={'/sonquintpitchandputt'} style={{ textDecoration: 'none' }}>    
                        <img className='club-card__img' src={son_quint_pitchandputt_club} alt='Son Quint Pitch and Putt Golf Club' />
                        <div className='club-card__details'>
                            <img className='club-card__details--img' src={LogoSonQuintPP} alt='Son Quint Pitch and Putt Golf Club Logo' />  
                            <p className='club-card__details--title'>Palma Pitch and Putt</p>
                            <p className='club-card__details--subtitle'>9 Hoyos | Palma de Mallorca, Mallorca.</p>
                        </div>
                </Link>  
                </div>  
                <div className='club-card' >
                <Link to={'/sonvidagreen'} style={{ textDecoration: 'none' }}>
                        <img className='club-card__img' src={son_vida_green_club} alt='Son Vida Golf Club | Green' />
                        <div className='club-card__details'>
                            <img className='club-card__details--img' src={LogoSonVida} alt='Son Vida Golf Club Logo' />
                            <p className='club-card__details--title'>Son Vida Golf Club | Green</p>
                            <p className='club-card__details--subtitle'>18 Hoyos | Palma de Mallorca, Mallorca.</p>
                        </div>
                </Link>        
                </div>
                <div className='club-card' >
                <Link to={'/sonvidaorange'} style={{ textDecoration: 'none' }}>
                        <img className='club-card__img' src={son_vida_orange_club} alt='Son Vida Golf Club | Orange' />
                        <div className='club-card__details'>
                            <img className='club-card__details--img' src={LogoSonVida} alt='Son Vida Golf Club Logo' />  
                            <p className='club-card__details--title'>Son Vida Golf Club | Orange</p>
                            <p className='club-card__details--subtitle'>18 Hoyos | Palma de Mallorca, Mallorca.</p>
                        </div>
                </Link>
                </div>
                <div className='club-card' >
                <Link to={'/golfpollensa'} style={{ textDecoration: 'none' }}>
                        <img className='club-card__img' src={golf_pollensa} alt='Golf Pollensa' />
                        <div className='club-card__details'>
                            <img className='club-card__details--img' src={LogoGolfPollensa} alt='Golf Pollensa Logo' />
                            <p className='club-card__details--title'>Golf Pollensa</p>
                            <p className='club-card__details--subtitle'>18 Hoyos | Pollensa, Mallorca.</p>
                        </div>
                </Link>
                </div>
                <div className='club-card' >
                <Link to={'/golfandratx'} style={{ textDecoration: 'none' }}>
                        <img className='club-card__img' src={golf_andratx} alt='Golf Andratx' />
                        <div className='club-card__details'>
                            <img className='club-card__details--img' src={LogoGolfAndratx} alt='Golf Andratx Logo' />
                            <p className='club-card__details--title'>Golf Andratx </p>
                            <p className='club-card__details--subtitle'>18 Hoyos | Andratx, Mallorca.</p>
                        </div>
                </Link>
                </div>
                <div className='club-card' >
                <Link to={'/realgolfdebendinat'} style={{ textDecoration: 'none' }}>
                        <img className='club-card__img' src={real_golf_bendinat} alt='Real Golf de Bendinat' />
                        <div className='club-card__details'>
                            <img className='club-card__details--img' src={LogoBendinat} alt='Real Golf de Bendinat Logo' />
                            <p className='club-card__details--title'>Real Golf de Bendinat </p>
                            <p className='club-card__details--subtitle'>18 Hoyos | Bendinat, Mallorca.</p>
                        </div>
                </Link>
                </div>
                <div className='club-card' >
                <Link to={'/golfsantaponsa1'} style={{ textDecoration: 'none' }}>
                        <img className='club-card__img' src={golf_santa_ponsa_1} alt='Golf Santa Ponsa I' />
                        <div className='club-card__details'>
                            <img className='club-card__details--img' src={LogoSantaPonsa1} alt='Golf Santa Ponsa I Logo' />
                            <p className='club-card__details--title'>Golf Santa Ponsa I </p>
                            <p className='club-card__details--subtitle'>18 Hoyos | Santa Ponsa, Mallorca.</p>
                        </div>
                </Link>
                </div>
                <div className='club-card' >
                <Link to={'/golfsantaponsa2'} style={{ textDecoration: 'none' }}>
                        <img className='club-card__img' src={golf_santa_ponsa_2} alt='Golf Santa Ponsa II' />
                        <div className='club-card__details'>
                            <img className='club-card__details--img' src={LogoSantaPonsa2} alt='Golf Santa Ponsa II Logo' />
                            <p className='club-card__details--title'>Golf Santa Ponsa II </p>
                            <p className='club-card__details--subtitle'>18 Hoyos | Santa Ponsa, Mallorca.</p>
                        </div>
                </Link>
                </div>
                <div className='club-card' >
                <Link to={'/golfsantaponsa3'} style={{ textDecoration: 'none' }}>
                        <img className='club-card__img' src={golf_santa_ponsa_3} alt='Golf Santa Ponsa III' />
                        <div className='club-card__details'>
                            <img className='club-card__details--img' src={LogoSantaPonsa3} alt='Golf Santa Ponsa III Logo' />
                            <p className='club-card__details--title'>Golf Santa Ponsa III </p>
                            <p className='club-card__details--subtitle'>9 Hoyos | Santa Ponsa, Mallorca.</p>
                        </div>
                </Link>
                </div> 
                <div className='club-card' >
                <Link to={'/tgolf'} style={{ textDecoration: 'none' }}>
                        <img className='club-card__img' src={t_golf} alt='T Golf Club' />
                        <div className='club-card__details'>
                            <img className='club-card__details--img' src={LogoTGolf} alt='T Golf Club Logo' />
                            <p className='club-card__details--title'>T Golf Club </p>
                            <p className='club-card__details--subtitle'>18 Hoyos | Calvià, Mallorca.</p>
                        </div>
                </Link>
                </div>
                <div className='club-card' >
                <Link to={'/songual'} style={{ textDecoration: 'none' }}>
                        <img className='club-card__img' src={son_gual} alt='Son Gual' />
                        <div className='club-card__details'>
                            <img className='club-card__details--img' src={LogoSonGual} alt='Son Gual Logo' />
                            <p className='club-card__details--title'>Son Gual Golf </p>
                            <p className='club-card__details--subtitle'>18 Hoyos | Manacor, Mallorca.</p>
                        </div>
                </Link>
                </div>
                <div className='club-card' >
                <Link to={'/golfpark'} style={{ textDecoration: 'none' }}>
                        <img className='club-card__img' src={golf_park} alt='Golf Park Mallorca' />
                        <div className='club-card__details'>
                            <img className='club-card__details--img' src={LogoPark} alt='Golf Park Mallorca Logo' />
                            <p className='club-card__details--title'>Golf Park Mallorca </p>
                            <p className='club-card__details--subtitle'>18 Hoyos | Palma de Mallorca, Mallorca.</p>
                        </div>
                </Link>
                </div>
                <div className='club-card' >
                <Link to={'/golfmaioris'} style={{ textDecoration: 'none' }}>
                        <img className='club-card__img' src={golf_maioris} alt='Golf Maioris' />
                        <div className='club-card__details'>
                            <img className='club-card__details--img' src={LogoMaioris} alt='Golf Maioris Logo' />
                            <p className='club-card__details--title'>Golf Mairois </p>
                            <p className='club-card__details--subtitle'>18 Hoyos | Palma de Mallorca, Mallorca.</p>
                        </div>
                </Link>
                </div>
                <div className='club-card' >
                <Link to={'/sonantemeast'} style={{ textDecoration: 'none' }}>
                        <img className='club-card__img' src={son_antem_east} alt='Golf Son Antem East' />
                        <div className='club-card__details'>
                            <img className='club-card__details--img' src={LogoSonantem} alt='Son Antem Logo' />
                            <p className='club-card__details--title'>Golf Son Antem | East </p>
                            <p className='club-card__details--subtitle'>18 Hoyos | Llucmajor, Mallorca.</p>
                        </div>
                </Link>
                </div>
                <div className='club-card' >
                <Link to={'/sonantemwest'} style={{ textDecoration: 'none' }}>
                        <img className='club-card__img' src={son_antem_west} alt='Golf Son Antem West' />
                        <div className='club-card__details'>
                            <img className='club-card__details--img' src={LogoSonantem} alt='Son Antem Logo' />
                            <p className='club-card__details--title'>Golf Son Antem | West </p>
                            <p className='club-card__details--subtitle'>18 Hoyos | Llucmajor, Mallorca.</p>
                        </div>
                </Link>
                </div>
                <div className='club-card' >
                <Link to={'/valldorgolf'} style={{ textDecoration: 'none' }}>
                        <img className='club-card__img' src={vall_dor_golf} alt='Vall dOr Golf' />
                        <div className='club-card__details'>
                            <img className='club-card__details--img' src={LogovallDor} alt='Vall dOr Golf Logo' />
                            <p className='club-card__details--title'>Vall d'Or Golf </p>
                            <p className='club-card__details--subtitle'>18 Hoyos | S'Horta, Mallorca.</p>
                        </div>
                </Link>
                </div>
                <div className='club-card' >
                <Link to={'/golfsonservera'} style={{ textDecoration: 'none' }}>
                        <img className='club-card__img' src={son_severa} alt='Golf Son Servera' />
                        <div className='club-card__details'>
                            <img className='club-card__details--img' src={LogoSonServera} alt='Golf Son Servera Logo' />
                            <p className='club-card__details--title'>Golf Son Servera </p>
                            <p className='club-card__details--subtitle'>18 Hoyos | Son Servera, Mallorca.</p>
                        </div>
                </Link>
                </div>
                <div className='club-card' >
                <Link to={'/golfalcanada'} style={{ textDecoration: 'none' }}>
                        <img className='club-card__img' src={golf_alcanada} alt='Golf Alcanada' />
                        <div className='club-card__details'>
                            <img className='club-card__details--img' src={LogoAlcanada} alt='SGolf Alcanada Logo' />
                            <p className='club-card__details--title'>Golf Alcanada </p>
                            <p className='club-card__details--subtitle'>18 Hoyos | Port d'Alcudia, Mallorca.</p>
                        </div>
                </Link>
                </div>
                <div className='club-card' >
                <Link to={'/sontermes'} style={{ textDecoration: 'none' }}>
                        <img className='club-card__img' src={son_termes} alt='Golf Son Termes' />
                        <div className='club-card__details'>
                            <img className='club-card__details--img' src={LogoSonTermes} alt='Golf Son Termes Logo' />
                            <p className='club-card__details--title'>Golf Son Termes </p>
                            <p className='club-card__details--subtitle'>18 Hoyos | Bunyola, Mallorca.</p>
                        </div>
                </Link>
                </div>
                <div className='club-card' >
                <Link to={'/capdeperagolf'} style={{ textDecoration: 'none' }}>
                        <img className='club-card__img' src={capdepera_golf} alt='Capdepera Golf' />
                        <div className='club-card__details'>
                            <img className='club-card__details--img' src={LogoCapdepra} alt='Capdepera Golf Logo' />
                            <p className='club-card__details--title'>Capdepera Golf </p>
                            <p className='club-card__details--subtitle'>18 Hoyos | Artà, Mallorca.</p>
                        </div>
                </Link>
                </div>
                <div className='club-card' >
                <Link to={'/pulamallorca'} style={{ textDecoration: 'none' }}>
                        <img className='club-card__img' src={pula_mallorca} alt='Golf Pula Mallorca' />
                        <div className='club-card__details'>
                            <img className='club-card__details--img' src={LogoPula} alt='Golf Pula Mallorca Logo' />
                            <p className='club-card__details--title'>Golf Pula Mallorca </p>
                            <p className='club-card__details--subtitle'>18 Hoyos | Son Servera, Mallorca.</p>
                        </div>
                </Link>
                </div>
                <div className='club-card' >
                <Link to={'/canyamelgolf'} style={{ textDecoration: 'none' }}>
                        <img className='club-card__img' src={canyamel_golf} alt='Canyamel Golf' />
                        <div className='club-card__details'>
                            <img className='club-card__details--img' src={LogoCanyamel} alt='Canyamel Golf Logo' />
                            <p className='club-card__details--title'>Canyamel Golf</p>
                            <p className='club-card__details--subtitle'>18 Hoyos | Canyamel, Mallorca.</p>
                        </div>
                </Link>
                </div>
            </div>
        </div>
    );
};

export default CourseSelection;