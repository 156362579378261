// Importación imágenes
import Logo from '../assets/statics/club_logos/son_gual_logo.png'

export const courseData = {
    1: {
        gp: 401,
        g: 373,
        lp: 348,
        l: 307,
        par: 4,
        hcp: 7
    },
    2: {
        gp: 322,
        g: 299,
        lp: 272,
        l: 261,
        par: 4,
        hcp: 15
    },
    3: {
        gp: 307,
        g: 299,
        lp: 268,
        l: 256,
        par: 4,
        hcp: 13
    },
    4: {
        gp: 471,
        g: 457,
        lp: 414,
        l: 383,
        par: 5,
        hcp: 11
    },
    5: {
        gp: 119,
        g: 112,
        lp: 107,
        l: 96,
        par: 3,
        hcp: 5
    },
    6: {
        gp: 449,
        g: 442,
        lp: 420,
        l: 411,
        par: 5,
        hcp: 9
    },
    7: {
        gp: 429,
        g: 381,
        lp: 344,
        l: 314,
        par: 4,
        hcp: 1
    },
    8: {
        gp: 330,
        g: 318,
        lp: 294,
        l: 273,
        par: 4,
        hcp: 17
    },
    9: {
        gp: 190,
        g: 170,
        lp: 157,
        l: 132,
        par: 3,
        hcp: 3
    },
    10: {
        gp: 362,
        g: 355,
        lp: 286,
        l: 250,
        par: 4,
        hcp: 10
    },
    11: {
        gp: 418,
        g: 410,
        lp: 366,
        l: 322,
        par: 4,
        hcp: 4
    },
    12: {
        gp: 479,
        g: 468,
        lp: 412,
        l: 376,
        par: 5,
        hcp: 14
    },
    13: {
        gp: 377,
        g: 369,
        lp: 363,
        l: 326,
        par: 4,
        hcp: 12
    },
    14: {
        gp: 346,
        g: 329,
        lp: 323,
        l: 277,
        par: 4,
        hcp: 16
    },
    15: {
        gp: 167,
        g: 147,
        lp: 138,
        l: 100,
        par: 3,
        hcp: 18
    },
    16: { 
        gp: 416,
        g: 406,
        lp: 364,
        l: 344,
        par: 4,
        hcp: 6
    },
    17: {
        gp: 177,
        g: 166,
        lp: 150,
        l: 118,
        par: 3,
        hcp: 8
    },
    18: {
        gp: 497,
        g: 482,
        lp: 449,
        l: 415,
        par: 5,
        hcp: 2
    },
    19:{
        totalGp: 6257,
        totalG: 5983,
        totalLp: 5475,
        totalL: 4961,
        totalPar: "72 (36)",
    },
    20:{
        title: "Golf Son Gual",
        image: Logo
    }
}