// Importación imágenes
import Logo from '../assets/statics/club_logos/golf_pollensa_logo.png'

export const courseData = {
    1: {
        gp: "-",
        g: 155,
        lp: "-",
        l: 130,
        par: 3,
        hcp: 9
    },
    2: {
        gp: "-",
        g: 521,
        lp: "-",
        l: 475,
        par: 5,
        hcp: 5
    },
    3: {
        gp: "-",
        g: 351,
        lp: "-",
        l: 328,
        par: 4,
        hcp: 3
    },
    4: {
        gp: "-",
        g: 134,
        lp: "-",
        l: 102,
        par: 3,
        hcp: 11
    },
    5: {
        gp: "-",
        g: 265,
        lp: "-",
        l: 219,
        par: 4,
        hcp: 15
    },
    6: {
        gp: "-",
        g: 278,
        lp: "-",
        l: 258,
        par: 4,
        hcp: 17
    },
    7: {
        gp: "-",
        g: 335,
        lp: "-",
        l: 291,
        par: 4,
        hcp: 1
    },
    8: {
        gp: "-",
        g: 103,
        lp: "-",
        l: 92,
        par: 3,
        hcp: 13
    },
    9: {
        gp: "-",
        g: 466,
        lp: "-",
        l: 447,
        par: 5,
        hcp: 7
    },
    10:{
        totalGp: "-",
        totalG: 2608,
        totalLp: "-",
        totalL: 2342,
        totalPar: 35,
    },
    11:{
        title: "Golf Pollença",
        image: Logo
    }
}