import html2canvas from "html2canvas";

async function downloadHook() {
    const canvas = await html2canvas(document.getElementById("scorecard_table"));
    canvas.style.display = "none";
    document.body.appendChild(canvas);
    const image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
    const a = document.createElement("a");
    a.setAttribute("download", `scorecard.png`);
    a.setAttribute("href", image);
    a.click();
}

export default downloadHook;