// Importación imágenes
import Logo from '../assets/statics/club_logos/golf_santa_ponsa_logo.png'

export const courseData = {
    1: {
        gp: 350,
        g: 332,
        lp: 300,
        l: 293,
        par: 4,
        hcp: 2
    },
    2: {
        gp: 157,
        g: 140,
        lp: 114,
        l: 100,
        par: 3,
        hcp: 16
    },
    3: {
        gp: 450,
        g: 437,
        lp: 391,
        l: 383,
        par: 5,
        hcp: 8
    },
    4: {
        gp: 292,
        g: 279,
        lp: 266,
        l: 251,
        par: 4,
        hcp: 18
    },
    5: {
        gp: 418,
        g: 398,
        lp: 365,
        l: 345,
        par: 4,
        hcp: 4
    },
    6: {
        gp: 352,
        g: 339,
        lp: 319,
        l: 312,
        par: 4,
        hcp: 6
    },
    7: {
        gp: 160,
        g: 149,
        lp: 138,
        l: 128,
        par: 3,
        hcp: 12
    },
    8: {
        gp: 484,
        g: 465,
        lp: 416,
        l: 401,
        par: 5,
        hcp: 10
    },
    9: {
        gp: 348,
        g: 331,
        lp: 256,
        l: 244,
        par: 4,
        hcp: 14
    },
    10: {
        gp: 289,
        g: 275,
        lp: 262,
        l: 250,
        par: 4,
        hcp: 9
    },
    11: {
        gp: 384,
        g: 367,
        lp: 311,
        l: 305,
        par: 4,
        hcp: 1
    },
    12: {
        gp: 155,
        g: 124,
        lp: 102,
        l: 87,
        par: 3,
        hcp: 17
    },
    13: {
        gp: 367,
        g: 349,
        lp: 325,
        l: 298,
        par: 4,
        hcp: 15
    },
    14: {
        gp: 363,
        g: 347,
        lp: 332,
        l: 321,
        par: 4,
        hcp: 5
    },
    15: {
        gp: 504,
        g: 472,
        lp: 410,
        l: 397,
        par: 5,
        hcp: 11
    },
    16: { 
        gp: 340,
        g: 326,
        lp: 303,
        l: 291,
        par: 4,
        hcp: 13
    },
    17: {
        gp: 474,
        g: 436,
        lp: 403,
        l: 388,
        par: 5,
        hcp: 3
    },
    18: {
        gp: 166,
        g: 140,
        lp: 115,
        l: 78,
        par: 3,
        hcp: 7
    },
    19:{
        totalGp: 6053,
        totalG: 5706,
        totalLp: 5128,
        totalL: 4872,
        totalPar: "72 (36)",
    },
    20:{
        title: "Golf Santa Ponsa II",
        image: Logo
    }
}