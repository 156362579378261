// Importación imágenes
import Logo from '../assets/statics/club_logos/golf_andratx_logo.png'

export const courseData = {
    1: {
        gp: 352,
        g: 328,
        lp: 309,
        l: 274,
        par: 4,
        hcp: 11
    },
    2: {
        gp: 161,
        g: 148,
        lp: 139,
        l: 126,
        par: 3,
        hcp: 7
    },
    3: {
        gp: 310,
        g: 278,
        lp: 274,
        l: 251,
        par: 4,
        hcp: 17
    },
    4: {
        gp: 161,
        g: 151,
        lp: 143,
        l: 132,
        par: 3,
        hcp: 9
    },
    5: {
        gp: 485,
        g: 448,
        lp: 395,
        l: 373,
        par: 5,
        hcp: 5
    },
    6: {
        gp: 609,
        g: 532,
        lp: 476,
        l: 454,
        par: 5,
        hcp: 1
    },
    7: {
        gp: 242,
        g: 229,
        lp: 209,
        l: 193,
        par: 4,
        hcp: 15
    },
    8: {
        gp: 405,
        g: 391,
        lp: 382,
        l: 364,
        par: 4,
        hcp: 3
    },
    9: {
        gp: 158,
        g: 121,
        lp: 111,
        l: 91,
        par: 3,
        hcp: 13
    },
    10: {
        gp: 484,
        g: 465,
        lp: 439,
        l: 414,
        par: 5,
        hcp: 8
    },
    11: {
        gp: 426,
        g: 403,
        lp: 356,
        l: 346,
        par: 4,
        hcp: 2
    },
    12: {
        gp: 346,
        g: 336,
        lp: 257,
        l: 251,
        par: 4,
        hcp: 6
    },
    13: {
        gp: 371,
        g: 343,
        lp: 337,
        l: 328,
        par: 4,
        hcp: 10
    },
    14: {
        gp: 279,
        g: 273,
        lp: 258,
        l: 232,
        par: 4,
        hcp: 18
    },
    15: {
        gp: 186,
        g: 174,
        lp: 169,
        l: 162,
        par: 3,
        hcp: 16
    },
    16: { 
        gp: 315,
        g: 304,
        lp: 291,
        l: 265,
        par: 4,
        hcp: 14
    },
    17: {
        gp: 344,
        g: 324,
        lp: 297,
        l: 279,
        par: 4,
        hcp: 12
    },
    18: {
        gp: 455,
        g: 435,
        lp: 426,
        l: 378,
        par: 5,
        hcp: 4
    },
    19:{
        totalGp: 6089,
        totalG: 5683,
        totalLp: 5268,
        totalL: 4913,
        totalPar: "72 (36)",
    },
    20:{
        title: "Golf Andratx",
        image: Logo
    }
}