// Importación imágenes
import Logo from '../assets/statics/club_logos/son_severa_logo.png'

export const courseData = {
    1: {
        gp: 339,
        g: 323,
        lp: 301,
        l: 281,
        par: 4,
        hcp: 11
    },
    2: {
        gp: 371,
        g: 349,
        lp: 338,
        l: 322,
        par: 4,
        hcp: 6
    },
    3: {
        gp: 122,
        g: 115,
        lp: 102,
        l: 97,
        par: 3,
        hcp: 12
    },
    4: {
        gp: 354,
        g: 344,
        lp: 309,
        l: 294,
        par: 4,
        hcp: 7
    },
    5: {
        gp: 506,
        g: 457,
        lp: 440,
        l: 382,
        par: 5,
        hcp: 1
    },
    6: {
        gp: 312,
        g: 305,
        lp: 268,
        l: 260,
        par: 4,
        hcp: 5
    },
    7: {
        gp: 357,
        g: 351,
        lp: 310,
        l: 301,
        par: 4,
        hcp: 3
    },
    8: {
        gp: 176,
        g: 158,
        lp: 143,
        l: 130,
        par: 3,
        hcp: 15
    },
    9: {
        gp: 452,
        g: 432,
        lp: 416,
        l: 352,
        par: 5,
        hcp: 13
    },
    10: {
        gp: 338,
        g: 330,
        lp: 310,
        l: 272,
        par: 4,
        hcp: 10
    },
    11: {
        gp: 291,
        g: 278,
        lp: 261,
        l: 222,
        par: 4,
        hcp: 17
    },
    12: {
        gp: 375,
        g: 367,
        lp: 326,
        l: 320,
        par: 4,
        hcp: 4
    },
    13: {
        gp: 149,
        g: 141,
        lp: 130,
        l: 114,
        par: 3,
        hcp: 18
    },
    14: {
        gp: 344,
        g: 337,
        lp: 310,
        l: 286,
        par: 4,
        hcp: 8
    },
    15: {
        gp: 462,
        g: 430,
        lp: 405,
        l: 394,
        par: 5,
        hcp: 9
    },
    16: { 
        gp: 503,
        g: 475,
        lp: 425,
        l: 398,
        par: 5,
        hcp: 14
    },
    17: {
        gp: 417,
        g: 397,
        lp: 375,
        l: 314,
        par: 4,
        hcp: 2
    },
    18: {
        gp: 182,
        g: 167,
        lp: 140,
        l: 123,
        par: 3,
        hcp: 16
    },
    19:{
        totalGp: 6050,
        totalG: 5756,
        totalLp: 5309,
        totalL: 4862,
        totalPar: "72 (36)",
    },
    20:{
        title: "Club de Golf Son Servera",
        image: Logo
    }
}