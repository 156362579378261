// Importación imágenes
import Logo from '../assets/statics/club_logos/son_antem_logo.png'

export const courseData = {
    1: {
        gp: 470,
        g: 443,
        lp: 419,
        l: 376,
        par: 5,
        hcp: 9
    },
    2: {
        gp: 157,
        g: 119,
        lp: 98,
        l: 85,
        par: 3,
        hcp: 17
    },
    3: {
        gp: 307,
        g: 300,
        lp: 258,
        l: 242,
        par: 4,
        hcp: 7
    },
    4: {
        gp: 317,
        g: 310,
        lp: 277,
        l: 229,
        par: 4,
        hcp: 5
    },
    5: {
        gp: 186,
        g: 176,
        lp: 166,
        l: 132,
        par: 3,
        hcp: 15
    },
    6: {
        gp: 345,
        g: 334,
        lp: 311,
        l: 256,
        par: 4,
        hcp: 13
    },
    7: {
        gp: 528,
        g: 516,
        lp: 490,
        l: 481,
        par: 5,
        hcp: 1
    },
    8: {
        gp: 348,
        g: 338,
        lp: 323,
        l: 296,
        par: 4,
        hcp: 3
    },
    9: {
        gp: 339,
        g: 324,
        lp: 289,
        l: 281,
        par: 4,
        hcp: 11
    },
    10: {
        gp: 487,
        g: 464,
        lp: 430,
        l: 422,
        par: 5,
        hcp: 14
    },
    11: {
        gp: 369,
        g: 363,
        lp: 352,
        l: 328,
        par: 4,
        hcp: 6
    },
    12: {
        gp: 330,
        g: 308,
        lp: 272,
        l: 267,
        par: 4,
        hcp: 12
    },
    13: {
        gp: 176,
        g: 168,
        lp: 147,
        l: 123,
        par: 3,
        hcp: 16
    },
    14: {
        gp: 384,
        g: 367,
        lp: 340,
        l: 315,
        par: 4,
        hcp: 10
    },
    15: {
        gp: 383,
        g: 373,
        lp: 342,
        l: 326,
        par: 4,
        hcp: 8
    },
    16: { 
        gp: 166,
        g: 158,
        lp: 136,
        l: 123,
        par: 3,
        hcp: 18
    },
    17: {
        gp: 385,
        g: 369,
        lp: 328,
        l: 314,
        par: 4,
        hcp: 4
    },
    18: {
        gp: 514,
        g: 501,
        lp: 455,
        l: 422,
        par: 5,
        hcp: 2
    },
    19:{
        totalGp: 6191,
        totalG: 5931,
        totalLp: 5433,
        totalL: 5018,
        totalPar: "72 (36)",
    },
    20:{
        title: "Golf Son Antem | East",
        image: Logo
    }
}