import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';

import '../assets/styles/kingsplay.css';

// Importación imagenes
import random from '../assets/statics/others/random.png'

const results = [
  {name: "Ladrón", explanation: "Tras las salidas de todos los jugadores en un hoyo, permite al jugador seguir jugando con la bola de otro jugador. El jugador robado tendrá un golpe adicional de salida."},
  {name: "Dictador", explanation: "Obliga a todos los jugadores utilizar el mismo palo en el siguiente golpe."},
  {name: "Comodín", explanation: "El jugador tiene una bola de salida adicional pero deberá jugar esa bola, no la primera."},
  {name: "Tramontana", explanation: "Tras todas las salidas, los jugadores dropearán sus respectivas bolas 1 metro en la dirección que el jugador elija."},
  {name: "King", explanation: "El jugador elige un beneficio de su elección antes de iniciar el hoyo"}
];

const KingsPlay = () => {
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
      };

    const [result, setResult] = useState(false);

    function getRandomResult() {
        return results[Math.floor(Math.random() * results.length)];
    }

    function handleButtonClick() {
        setResult(getRandomResult());
        setTimeout(() => setResult(false), 4000) 
    }

  return (
    <div class="popupContent">
            <button type="button" class="popupContent-button_close" onClick={goBack}>X</button>
            <img class= "popupContent-img " src={random} alt='pick_Kingscard'/>
            <p class= "popupContent-text">¿Has hecho par o menos en el último hoyo? Prueba suerte:</p>
            <button 
                class="popupContent-button_number"
                value="1"
                name="playersNumber"
                onClick={handleButtonClick}
            >                        
                Nueva King card
            </button>

            <div class="cardContainer">
                {result ? (
                    <>
                        <h2 className="result-name">{result.name}</h2>
                        <p className="result-explanation">{result.explanation}</p>
                    </>
                    )
                    :(
                        <>
                        </>
                    )
                }
            </div>
    </div>
    );
}

export default KingsPlay;
