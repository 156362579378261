import React, {useEffect, useState} from 'react';
import { Routes, Route } from 'react-router-dom';
import { HashRouter } from "react-router-dom";

//Importación de las páginas
import Loader from '../components/Loader';
import Popup from '../components/Popup';
import KingsPlay from '../components/KingsPlay';

import CourseSelection from '../containers/00Courses';
import SCSonMuntaner from '../containers/01SCSonMuntaner';
import SCSonQuint from '../containers/02SCSonQuint';
import SCPalmaPitchAndPutt from '../containers/02SCPalmaPitchAndPutt';
import SCSonVidaGreen from '../containers/03SCSonVidaGreen';
import SCSonVidaOrange from '../containers/03SCSonVidaOrange';
import SCGolfPollensa from '../containers/04SCGolfPollensa';
import SCGolfAndratx from '../containers/05SCGolfAndratx';
import SCGolfSantaPonsa1 from '../containers/06SCGolfSantaPonsa1';
import SCGolfSantaPonsa2 from '../containers/06SCGolfSantaPonsa2';
import SCGolfSantaPonsa3 from '../containers/06SCGolfSantaPonsa3';
import SCRealGolfDeBendinat from '../containers/07SCRealGolfDeBendinat';
import SCTGolfClub from '../containers/08SCTGolfClub';
import SCSonGual from '../containers/09SCSonGual';
import SCGolfPark from '../containers/10SCGolfPark';
import SCGolfMaioris from '../containers/11SCGolfMaioris';
import SCSonAntemEast from '../containers/12SCSonAntem_East';
import SCSonAntemWest from '../containers/12SCSonAntem_West';
import SCVallDorGolf from '../containers/13SCVallDorGolf';
import SCGolfSonServera from '../containers/14SCGolfSonServera';
import SCGolfAlcanada from '../containers/15SCGolfAlcanada';
import SCSonTermes from '../containers/16SCSonTermes'
import SCCapdeperaGolf from '../containers/17SCCapdeperaGolf'
import SCPulaMallorca from '../containers/18SCPulaMallorca'
import SCRotanaGreens from '../containers/19SCRotanaGreens'
import SCCanyamelGolf from '../containers/20SCCanyamelGolf'

const App = () => {
    const [loading, setLoading] = useState(true)
  
    useEffect(() => {
      setTimeout(() => setLoading(false), 2000)
    }, [])
  
    return (
      <>
      {loading === false ? (
        <HashRouter>
            <Routes>
                <Route exact path='/editplayers' element={<Popup/>} />
                <Route exact path='/kingsplay' element={<KingsPlay/>} />
                <Route exact path='/' element={<CourseSelection/>} />
                <Route  exact path='/sonmuntaner' element={<SCSonMuntaner/>} />
                <Route  exact path='/sonquint' element={<SCSonQuint/>} />
                <Route  exact path='/sonquintpitchandputt' element={<SCPalmaPitchAndPutt/>} />
                <Route  exact path='/sonvidagreen' element={<SCSonVidaGreen/>} />
                <Route  exact path='/sonvidaorange' element={<SCSonVidaOrange/>} />
                <Route  exact path='/golfpollensa' element={<SCGolfPollensa/>} />
                <Route  exact path='/golfandratx' element={<SCGolfAndratx/>} />
                <Route  exact path='/golfsantaponsa1' element={<SCGolfSantaPonsa1/>} />
                <Route  exact path='/golfsantaponsa2' element={<SCGolfSantaPonsa2/>} />
                <Route  exact path='/golfsantaponsa3' element={<SCGolfSantaPonsa3/>} />
                <Route  exact path='/realgolfdebendinat' element={<SCRealGolfDeBendinat/>} />
                <Route  exact path='/tgolf' element={<SCTGolfClub/>} />
                <Route  exact path='/songual' element={<SCSonGual/>} />
                <Route  exact path='/golfpark' element={<SCGolfPark/>} />
                <Route  exact path='/golfmaioris' element={<SCGolfMaioris/>} />
                <Route  exact path='/sonantemeast' element={<SCSonAntemEast/>} />
                <Route  exact path='/sonantemwest' element={<SCSonAntemWest/>} />
                <Route  exact path='/valldorgolf' element={<SCVallDorGolf/>} />
                <Route  exact path='/golfsonservera' element={<SCGolfSonServera/>} />
                <Route  exact path='/golfalcanada' element={<SCGolfAlcanada/>} />
                <Route  exact path='/sontermes' element={<SCSonTermes/>} />
                <Route  exact path='/capdeperagolf' element={<SCCapdeperaGolf/>} />
                <Route  exact path='/pulamallorca' element={<SCPulaMallorca/>} />
                <Route  exact path='/rotanagreens' element={<SCRotanaGreens/>} />
                <Route  exact path='/canyamelgolf' element={<SCCanyamelGolf/>} />
            </Routes>
        </HashRouter>
        ) : (
          <Loader />
        )}
        </>
    );
  }
  
  export default App;