// Importación imágenes
import Logo from '../assets/statics/club_logos/real_golf_bendinat.png'

export const courseData = {
    1: {
        gp: "-",
        g: 344,
        lp: "-",
        l: 314,
        par: 4,
        hcp: 15
    },
    2: {
        gp: "-",
        g: 158,
        lp: "-",
        l: 147,
        par: 3,
        hcp: 11
    },
    3: {
        gp: "-",
        g: 330,
        lp: "-",
        l: 283,
        par: 4,
        hcp: 9
    },
    4: {
        gp: "-",
        g: 163,
        lp: "-",
        l: 130,
        par: 3,
        hcp: 7
    },
    5: {
        gp: "-",
        g: 396,
        lp: "-",
        l: 339,
        par: 4,
        hcp: 1
    },
    6: {
        gp: "-",
        g: 380,
        lp: "-",
        l: 354,
        par: 4,
        hcp: 3
    },
    7: {
        gp: "-",
        g: 180,
        lp: "-",
        l: 136,
        par: 3,
        hcp: 5
    },
    8: {
        gp: "-",
        g: 340,
        lp: "-",
        l: 287,
        par: 4,
        hcp: 17
    },
    9: {
        gp: "-",
        g: 334,
        lp: "-",
        l: 284,
        par: 4,
        hcp: 13
    },
    10: {
        gp: "-",
        g: 349,
        lp: "-",
        l: 320,
        par: 4,
        hcp: 2
    },
    11: {
        gp: "-",
        g: 329,
        lp: "-",
        l: 294,
        par: 4,
        hcp: 14
    },
    12: {
        gp: "-",
        g: 135,
        lp: "-",
        l: 122,
        par: 3,
        hcp: 18
    },
    13: {
        gp: "-",
        g: 294,
        lp: "-",
        l: 238,
        par: 4,
        hcp: 10
    },
    14: {
        gp: "-",
        g: 452,
        lp: "-",
        l: 435,
        par: 5,
        hcp: 12
    },
    15: {
        gp: "-",
        g: 345,
        lp: "-",
        l: 327,
        par: 4,
        hcp: 8
    },
    16: { 
        gp: "-",
        g: 328,
        lp: "-",
        l: 289,
        par: 4,
        hcp: 16
    },
    17: {
        gp:"-",
        g: 159,
        lp: "-",
        l: 119,
        par: 3,
        hcp: 6
    },
    18: {
        gp: "-",
        g: 454,
        lp: "-",
        l: 430,
        par: 5,
        hcp: 4
    },
    19:{
        totalGp: "-",
        totalG: 5470,
        totalLp: "-",
        totalL: 4848,
        totalPar: "69 (33)",
    },
    20:{
        title: "Real Golf de Bendinat",
        image: Logo
    }
}