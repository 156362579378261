// Importación imágenes
import Logo from '../assets/statics/club_logos/pula_mallorca_logo.png'

export const courseData = {
    1: {
        gp: "-",
        g: 373,
        lp: 358,
        l: 342,
        par: 4,
        hcp: 6
    },
    2: {
        gp: "-",
        g: 156,
        lp: 144,
        l: 130,
        par: 3,
        hcp: 16
    },
    3: {
        gp: "-",
        g: 332,
        lp: 299,
        l: 268,
        par: 4,
        hcp: 4
    },
    4: {
        gp: "-",
        g: 348,
        lp: 334,
        l: 332,
        par: 4,
        hcp: 18
    },
    5: {
        gp: "-",
        g: 304,
        lp: 290,
        l: 266,
        par: 4,
        hcp: 14
    },
    6: {
        gp: "-",
        g: 465,
        lp: 448,
        l: 425,
        par: 5,
        hcp: 12
    },
    7: {
        gp: "-",
        g: 375,
        lp: 366,
        l: 355,
        par: 4,
        hcp: 10
    },
    8: {
        gp: "-",
        g: 174,
        lp: 149,
        l: 131,
        par: 3,
        hcp: 8
    },
    9: {
        gp: "-",
        g: 427,
        lp: 413,
        l: 387,
        par: 5,
        hcp: 2
    },
    10: {
        gp: "-",
        g: 449,
        lp: 431,
        l: 387,
        par: 5,
        hcp: 13
    },
    11: {
        gp: "-",
        g: 278,
        lp: 253,
        l: 243,
        par: 4,
        hcp: 9
    },
    12: {
        gp: "-",
        g: 431,
        lp: 398,
        l: 391,
        par: 5,
        hcp: 17
    },
    13: {
        gp: "-",
        g: 153,
        lp: 142,
        l: 122,
        par: 3,
        hcp: 7
    },
    14: {
        gp: "-",
        g: 304,
        lp: 279,
        l: 273,
        par: 4,
        hcp: 11
    },
    15: {
        gp: "-",
        g: 395,
        lp: 346,
        l: 339,
        par: 4,
        hcp: 3
    },
    16: { 
        gp: "-",
        g: 365,
        lp: 341,
        l: 323,
        par: 4,
        hcp: 5
    },
    17: {
        gp: "-",
        g: 389,
        lp: 334,
        l: 284,
        par: 4,
        hcp: 1
    },
    18: {
        gp: "-",
        g: 151,
        lp: 127,
        l: 119,
        par: 3,
        hcp: 15
    },
    19:{
        totalGp: "-",
        totalG: 5869,
        totalLp: 5452,
        totalL: 5117,
        totalPar: "72 (36)",
    },
    20:{
        title: "Golf Pula Mallorca",
        image: Logo
    }
}