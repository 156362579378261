import React from 'react';
import {useNavigate} from 'react-router-dom';

// Importación estilos
import '../assets/styles/popup.css';

// Importación imagenes
import playerImg from '../assets/statics/others/jugador-de-golf.png'

const Popup = () => {
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
      };

    const handleButton = function (e) {
        sessionStorage.setItem(e.target.name, e.target.value);
        setTimeout(() => navigate(-1), 500) 
    }; 
    return( 
            <div class="popupContent">
                <button type="button" class="popupContent-button_close" onClick={goBack}>X</button>
                <img class= "popupContent-img " src={playerImg} alt='editPlayers'/>
                <p class= "popupContent-text">Nº de jugadores:</p>
                <button 
                    class="popupContent-button_number"
                    value="1"
                    name="playersNumber"
                    onClick={handleButton}
                    >
                        1 jugador
                </button>
                <button 
                    class="popupContent-button_number"
                    value="2"
                    name="playersNumber"
                    onClick={handleButton}
                    >
                        2 jugador
                </button>
                <button 
                    class="popupContent-button_number"
                    value="3"
                    name="playersNumber"
                    onClick={handleButton}
                    >
                        3 jugador
                </button>
                <button 
                    class="popupContent-button_number"
                    value="4"
                    name="playersNumber"
                    onClick={handleButton}
                    >
                        4 jugador
                </button>
            </div>
    )
};
export default Popup;