// Importación imágenes
import Logo from '../assets/statics/club_logos/son_termes_logo.png'

export const courseData = {
    1: {
        gp: 233,
        g: 229,
        lp: 194,
        l: 180,
        par: 4,
        hcp: 17
    },
    2: {
        gp: 276,
        g: 276,
        lp: 235,
        l: 230,
        par: 4,
        hcp: 9
    },
    3: {
        gp: 314,
        g: 291,
        lp: 278,
        l: 274,
        par: 4,
        hcp: 13
    },
    4: {
        gp: 360,
        g: 334,
        lp: 317,
        l: 261,
        par: 4,
        hcp: 1
    },
    5: {
        gp: 490,
        g: 455,
        lp: 438,
        l: 425,
        par: 5,
        hcp: 3
    },
    6: {
        gp: 461,
        g: 446,
        lp: 418,
        l: 390,
        par: 5,
        hcp: 7
    },
    7: {
        gp: 161,
        g: 152,
        lp: 132,
        l: 81,
        par: 3,
        hcp: 15
    },
    8: {
        gp: 335,
        g: 293,
        lp: 259,
        l: 243,
        par: 4,
        hcp: 5
    },
    9: {
        gp: 178,
        g: 171,
        lp: 160,
        l: 143,
        par: 3,
        hcp: 11
    },
    10: {
        gp: 419,
        g: 402,
        lp: 366,
        l: 342,
        par: 4,
        hcp: 2
    },
    11: {
        gp: 337,
        g: 328,
        lp: 316,
        l: 295,
        par: 4,
        hcp: 12
    },
    12: {
        gp: 118,
        g: 101,
        lp: 94,
        l: 89,
        par: 3,
        hcp: 18
    },
    13: {
        gp: 298,
        g: 283,
        lp: 263,
        l: 260,
        par: 4,
        hcp: 4
    },
    14: {
        gp: 362,
        g: 334,
        lp: 320,
        l: 281,
        par: 4,
        hcp: 10
    },
    15: {
        gp: 178,
        g: 172,
        lp: 163,
        l: 144,
        par: 3,
        hcp: 14
    },
    16: { 
        gp: 362,
        g: 352,
        lp: 338,
        l: 322,
        par: 4,
        hcp: 8
    },
    17: {
        gp: 362,
        g: 345,
        lp: 329,
        l: 313,
        par: 4,
        hcp: 6
    },
    18: {
        gp: 338,
        g: 318,
        lp: 292,
        l: 237,
        par: 4,
        hcp: 16
    },
    19:{
        totalGp: 5582,
        totalG: 5282,
        totalLp: 4912,
        totalL: 4510,
        totalPar: "70 (36)",
    },
    20:{
        title: "Golf Son Termes",
        image: Logo
    }
}