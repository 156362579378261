import React from 'react';

// Importación estilos
import '../assets/styles/scorecard.css'
import "../assets/styles/button.css";

// Importación hooks
import downloadHook from "../hooks/downloadHook"

// Importación de componentes
import HeaderScoreCard from "./HeaderScoreCard";

const CourseScorecardp4 = (props) => {

    React.useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    const [input, setInput] = React.useState({
        playerName: sessionStorage.getItem('playerName') ? sessionStorage.getItem('playerName') : '',
        p1h1: sessionStorage.getItem('p1h1') ? sessionStorage.getItem('p1h1') : '',
        p1h2: sessionStorage.getItem('p1h2') ? sessionStorage.getItem('p1h2') : '',
        p1h3: sessionStorage.getItem('p1h3') ? sessionStorage.getItem('p1h3') : '',
        p1h4: sessionStorage.getItem('p1h4') ? sessionStorage.getItem('p1h4') : '',
        p1h5: sessionStorage.getItem('p1h5') ? sessionStorage.getItem('p1h5') : '',
        p1h6: sessionStorage.getItem('p1h6') ? sessionStorage.getItem('p1h6') : '',
        p1h7: sessionStorage.getItem('p1h7') ? sessionStorage.getItem('p1h7') : '',
        p1h8: sessionStorage.getItem('p1h8') ? sessionStorage.getItem('p1h8') : '',
        p1h9: sessionStorage.getItem('p1h9') ? sessionStorage.getItem('p1h9') : '',
        p1h10: sessionStorage.getItem('p1h10') ? sessionStorage.getItem('p1h10') : '',
        p1h11: sessionStorage.getItem('p1h11') ? sessionStorage.getItem('p1h11') : '',
        p1h12: sessionStorage.getItem('p1h12') ? sessionStorage.getItem('p1h12') : '',
        p1h13: sessionStorage.getItem('p1h13') ? sessionStorage.getItem('p1h13') : '',
        p1h14: sessionStorage.getItem('p1h14') ? sessionStorage.getItem('p1h14') : '',
        p1h15: sessionStorage.getItem('p1h15') ? sessionStorage.getItem('p1h15') : '',
        p1h16: sessionStorage.getItem('p1h16') ? sessionStorage.getItem('p1h16') : '',
        p1h17: sessionStorage.getItem('p1h17') ? sessionStorage.getItem('p1h17') : '',
        p1h18: sessionStorage.getItem('p1h18') ? sessionStorage.getItem('p1h18') : '',
        playerName2: sessionStorage.getItem('playerName2') ? sessionStorage.getItem('playerName2') : '',
        p2h1: sessionStorage.getItem('p2h1') ? sessionStorage.getItem('p2h1') : '',
        p2h2: sessionStorage.getItem('p2h2') ? sessionStorage.getItem('p2h2') : '',
        p2h3: sessionStorage.getItem('p2h3') ? sessionStorage.getItem('p2h3') : '',
        p2h4: sessionStorage.getItem('p2h4') ? sessionStorage.getItem('p2h4') : '',
        p2h5: sessionStorage.getItem('p2h5') ? sessionStorage.getItem('p2h5') : '',
        p2h6: sessionStorage.getItem('p2h6') ? sessionStorage.getItem('p2h6') : '',
        p2h7: sessionStorage.getItem('p2h7') ? sessionStorage.getItem('p2h7') : '',
        p2h8: sessionStorage.getItem('p2h8') ? sessionStorage.getItem('p2h8') : '',
        p2h9: sessionStorage.getItem('p2h9') ? sessionStorage.getItem('p2h9') : '',
        p2h10: sessionStorage.getItem('p2h10') ? sessionStorage.getItem('p2h10') : '',
        p2h11: sessionStorage.getItem('p2h11') ? sessionStorage.getItem('p2h11') : '',
        p2h12: sessionStorage.getItem('p2h12') ? sessionStorage.getItem('p2h12') : '',
        p2h13: sessionStorage.getItem('p2h13') ? sessionStorage.getItem('p2h13') : '',
        p2h14: sessionStorage.getItem('p2h14') ? sessionStorage.getItem('p2h14') : '',
        p2h15: sessionStorage.getItem('p2h15') ? sessionStorage.getItem('p2h15') : '',
        p2h16: sessionStorage.getItem('p2h16') ? sessionStorage.getItem('p2h16') : '',
        p2h17: sessionStorage.getItem('p2h17') ? sessionStorage.getItem('p2h17') : '',
        p2h18: sessionStorage.getItem('p2h18') ? sessionStorage.getItem('p2h18') : '',
        playerName3: sessionStorage.getItem('playerName3') ? sessionStorage.getItem('playerName3') : '',
        p3h1: sessionStorage.getItem('p3h1') ? sessionStorage.getItem('p3h1') : '',
        p3h2: sessionStorage.getItem('p3h2') ? sessionStorage.getItem('p3h2') : '',
        p3h3: sessionStorage.getItem('p3h3') ? sessionStorage.getItem('p3h3') : '',
        p3h4: sessionStorage.getItem('p3h4') ? sessionStorage.getItem('p3h4') : '',
        p3h5: sessionStorage.getItem('p3h5') ? sessionStorage.getItem('p3h5') : '',
        p3h6: sessionStorage.getItem('p3h6') ? sessionStorage.getItem('p3h6') : '',
        p3h7: sessionStorage.getItem('p3h7') ? sessionStorage.getItem('p3h7') : '',
        p3h8: sessionStorage.getItem('p3h8') ? sessionStorage.getItem('p3h8') : '',
        p3h9: sessionStorage.getItem('p3h9') ? sessionStorage.getItem('p3h9') : '',
        p3h10: sessionStorage.getItem('p3h10') ? sessionStorage.getItem('p3h10') : '',
        p3h11: sessionStorage.getItem('p3h11') ? sessionStorage.getItem('p3h11') : '',
        p3h12: sessionStorage.getItem('p3h12') ? sessionStorage.getItem('p3h12') : '',
        p3h13: sessionStorage.getItem('p3h13') ? sessionStorage.getItem('p3h13') : '',
        p3h14: sessionStorage.getItem('p3h14') ? sessionStorage.getItem('p3h14') : '',
        p3h15: sessionStorage.getItem('p3h15') ? sessionStorage.getItem('p3h15') : '',
        p3h16: sessionStorage.getItem('p3h16') ? sessionStorage.getItem('p3h16') : '',
        p3h17: sessionStorage.getItem('p3h17') ? sessionStorage.getItem('p3h17') : '',
        p3h18: sessionStorage.getItem('p3h18') ? sessionStorage.getItem('p3h18') : '',
        playerName4: sessionStorage.getItem('playerName4') ? sessionStorage.getItem('playerName4') : '',
        p4h1: sessionStorage.getItem('p4h1') ? sessionStorage.getItem('p4h1') : '',
        p4h2: sessionStorage.getItem('p4h2') ? sessionStorage.getItem('p4h2') : '',
        p4h3: sessionStorage.getItem('p4h3') ? sessionStorage.getItem('p4h3') : '',
        p4h4: sessionStorage.getItem('p4h4') ? sessionStorage.getItem('p4h4') : '',
        p4h5: sessionStorage.getItem('p4h5') ? sessionStorage.getItem('p4h5') : '',
        p4h6: sessionStorage.getItem('p4h6') ? sessionStorage.getItem('p4h6') : '',
        p4h7: sessionStorage.getItem('p4h7') ? sessionStorage.getItem('p4h7') : '',
        p4h8: sessionStorage.getItem('p4h8') ? sessionStorage.getItem('p4h8') : '',
        p4h9: sessionStorage.getItem('p4h9') ? sessionStorage.getItem('p4h9') : '',
        p4h10: sessionStorage.getItem('p4h10') ? sessionStorage.getItem('p4h10') : '',
        p4h11: sessionStorage.getItem('p4h11') ? sessionStorage.getItem('p4h11') : '',
        p4h12: sessionStorage.getItem('p4h12') ? sessionStorage.getItem('p4h12') : '',
        p4h13: sessionStorage.getItem('p4h13') ? sessionStorage.getItem('p4h13') : '',
        p4h14: sessionStorage.getItem('p4h14') ? sessionStorage.getItem('p4h14') : '',
        p4h15: sessionStorage.getItem('p4h15') ? sessionStorage.getItem('p4h15') : '',
        p4h16: sessionStorage.getItem('p4h16') ? sessionStorage.getItem('p4h16') : '',
        p4h17: sessionStorage.getItem('p4h17') ? sessionStorage.getItem('p4h17') : '',
        p4h18: sessionStorage.getItem('p4h18') ? sessionStorage.getItem('p4h18') : '',
    });

    const handleInput = function (e) {
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });
        sessionStorage.setItem(e.target.name, e.target.value);
    };

    const { 
        p1h1, 
        p1h2,
        p1h3,
        p1h4,
        p1h5,
        p1h6,
        p1h7,
        p1h8,
        p1h9,
        p1h10,
        p1h11,
        p1h12,
        p1h13,
        p1h14,
        p1h15,
        p1h16,
        p1h17,
        p1h18,
        p2h1, 
        p2h2,
        p2h3,
        p2h4,
        p2h5,
        p2h6,
        p2h7,
        p2h8,
        p2h9,
        p2h10,
        p2h11,
        p2h12,
        p2h13,
        p2h14,
        p2h15,
        p2h16,
        p2h17,
        p2h18,
        p3h1, 
        p3h2,
        p3h3,
        p3h4,
        p3h5,
        p3h6,
        p3h7,
        p3h8,
        p3h9,
        p3h10,
        p3h11,
        p3h12,
        p3h13,
        p3h14,
        p3h15,
        p3h16,
        p3h17,
        p3h18,
        p4h1, 
        p4h2,
        p4h3,
        p4h4,
        p4h5,
        p4h6,
        p4h7,
        p4h8,
        p4h9,
        p4h10,
        p4h11,
        p4h12,
        p4h13,
        p4h14,
        p4h15,
        p4h16,
        p4h17,
        p4h18,
     } = input;

    const result = Number(p1h1)
        + Number(p1h2)
        + Number(p1h3)
        + Number(p1h4)
        + Number(p1h5)
        + Number(p1h6)
        + Number(p1h7)
        + Number(p1h8)
        + Number(p1h9)
        + Number(p1h10)
        + Number(p1h11)
        + Number(p1h12)
        + Number(p1h13)
        + Number(p1h14)
        + Number(p1h15)
        + Number(p1h16)
        + Number(p1h17)
        + Number(p1h18);

    const result2 = Number(p2h1)
        + Number(p2h2)
        + Number(p2h3)
        + Number(p2h4)
        + Number(p2h5)
        + Number(p2h6)
        + Number(p2h7)
        + Number(p2h8)
        + Number(p2h9)
        + Number(p2h10)
        + Number(p2h11)
        + Number(p2h12)
        + Number(p2h13)
        + Number(p2h14)
        + Number(p2h15)
        + Number(p2h16)
        + Number(p2h17)
        + Number(p2h18);

    const result3 = Number(p3h1)
        + Number(p3h2)
        + Number(p3h3)
        + Number(p3h4)
        + Number(p3h5)
        + Number(p3h6)
        + Number(p3h7)
        + Number(p3h8)
        + Number(p3h9)
        + Number(p3h10)
        + Number(p3h11)
        + Number(p3h12)
        + Number(p3h13)
        + Number(p3h14)
        + Number(p3h15)
        + Number(p3h16)
        + Number(p3h17)
        + Number(p3h18);

    const result4 = Number(p4h1)
        + Number(p4h2)
        + Number(p4h3)
        + Number(p4h4)
        + Number(p4h5)
        + Number(p4h6)
        + Number(p4h7)
        + Number(p4h8)
        + Number(p4h9)
        + Number(p4h10)
        + Number(p4h11)
        + Number(p4h12)
        + Number(p4h13)
        + Number(p4h14)
        + Number(p4h15)
        + Number(p4h16)
        + Number(p4h17)
        + Number(p4h18);

    const currentDate = new Date();
    const date = `${currentDate.getDate()}/${currentDate.getMonth()+1}/${currentDate.getFullYear()}`;    
    return( 
        <div>
            <HeaderScoreCard />
            <div className="table-download" id="scorecard_table">
                <div className='intro-scorecard'>
                    <img className='title-scorecard__img' src={props.data[20].image} alt={props.data[20].title} />
                    <p className='title-scorecard'>{props.data[20].title}</p>
                </div> 
                <table className="customTable">
                                <thead>
                                    <tr>
                                        <th className="scorecard_title--standard">H.</th>
                                        <th className="scorecard_title--white">G.P</th>
                                        <th className="scorecard_title--yellow">G</th>
                                        <th className="scorecard_title--blue">L.P</th>
                                        <th className="scorecard_title--pink">L</th>
                                        <th className="scorecard_title--standard">Par</th>
                                        <th className="scorecard_title--standard">Hcp</th>
                                        <th className="scorecard_title--player">
                                            <input 
                                                className='edit_player'
                                                name= "playerName"  
                                                onChange={handleInput}
                                                value={input.playerName} 
                                                placeholder="Nombre">
                                            </input>
                                        </th>
                                        <th className="scorecard_title--player">
                                            <input 
                                                className='edit_player'
                                                name= "playerName2"  
                                                onChange={handleInput}
                                                value={input.playerName2} 
                                                placeholder="Nombre">
                                            </input>
                                        </th>
                                        <th className="scorecard_title--player">
                                            <input 
                                                className='edit_player'
                                                name= "playerName3"  
                                                onChange={handleInput}
                                                value={input.playerName3} 
                                                placeholder="Nombre">
                                            </input>
                                        </th>
                                        <th className="scorecard_title--player">
                                            <input 
                                                className='edit_player'
                                                name= "playerName4"  
                                                onChange={handleInput}
                                                value={input.playerName4} 
                                                placeholder="Nombre">
                                            </input>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                <tr>
                                        <td className="scorecard_input--hole">1</td>
                                        <td className="scorecard_input--white">{props.data[1].gp}</td>
                                        <td className="scorecard_input--yellow">{props.data[1].g}</td>
                                        <td className="scorecard_input--blue">{props.data[1].lp}</td>
                                        <td className="scorecard_input--pink">{props.data[1].l}</td>
                                        <td className="scorecard_input--standard">{props.data[1].par}</td>
                                        <td className="scorecard_input--standard">{props.data[1].hcp}</td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p1h1" 
                                                value={input.p1h1}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p2h1" 
                                                value={input.p2h1}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p3h1" 
                                                value={input.p3h1}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p4h1" 
                                                value={input.p4h1}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="scorecard_input--hole">2</td>
                                        <td className="scorecard_input--white">{props.data[2].gp}</td>
                                        <td className="scorecard_input--yellow">{props.data[2].g}</td>
                                        <td className="scorecard_input--blue">{props.data[2].lp}</td>
                                        <td className="scorecard_input--pink">{props.data[2].l}</td>
                                        <td className="scorecard_input--standard">{props.data[2].par}</td>
                                        <td className="scorecard_input--standard">{props.data[2].hcp}</td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p1h2" 
                                                value={input.p1h2}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p2h2" 
                                                value={input.p2h2}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p3h2" 
                                                value={input.p3h2}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p4h2" 
                                                value={input.p4h2}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="scorecard_input--hole">3</td>
                                        <td className="scorecard_input--white">{props.data[3].gp}</td>
                                        <td className="scorecard_input--yellow">{props.data[3].g}</td>
                                        <td className="scorecard_input--blue">{props.data[3].lp}</td>
                                        <td className="scorecard_input--pink">{props.data[3].l}</td>
                                        <td className="scorecard_input--standard">{props.data[3].par}</td>
                                        <td className="scorecard_input--standard">{props.data[3].hcp}</td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p1h3" 
                                                value={input.p1h3}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p2h3" 
                                                value={input.p2h3}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p3h3" 
                                                value={input.p3h3}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p4h3" 
                                                value={input.p4h3}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="scorecard_input--hole">4</td>
                                        <td className="scorecard_input--white">{props.data[4].gp}</td>
                                        <td className="scorecard_input--yellow">{props.data[4].g}</td>
                                        <td className="scorecard_input--blue">{props.data[4].lp}</td>
                                        <td className="scorecard_input--pink">{props.data[4].l}</td>
                                        <td className="scorecard_input--standard">{props.data[4].par}</td>
                                        <td className="scorecard_input--standard">{props.data[4].hcp}</td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p1h4" 
                                                value={input.p1h4}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p2h4" 
                                                value={input.p2h4}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p3h4" 
                                                value={input.p3h4}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p4h4" 
                                                value={input.p4h4}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="scorecard_input--hole">5</td>
                                        <td className="scorecard_input--white">{props.data[5].gp}</td>
                                        <td className="scorecard_input--yellow">{props.data[5].g}</td>
                                        <td className="scorecard_input--blue">{props.data[5].lp}</td>
                                        <td className="scorecard_input--pink">{props.data[5].l}</td>
                                        <td className="scorecard_input--standard">{props.data[5].par}</td>
                                        <td className="scorecard_input--standard">{props.data[5].hcp}</td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p1h5" 
                                                value={input.p1h5}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p2h5" 
                                                value={input.p2h5}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p3h5" 
                                                value={input.p3h5}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p4h5" 
                                                value={input.p4h5}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="scorecard_input--hole">6</td>
                                        <td className="scorecard_input--white">{props.data[6].gp}</td>
                                        <td className="scorecard_input--yellow">{props.data[6].g}</td>
                                        <td className="scorecard_input--blue">{props.data[6].lp}</td>
                                        <td className="scorecard_input--pink">{props.data[6].l}</td>
                                        <td className="scorecard_input--standard">{props.data[6].par}</td>
                                        <td className="scorecard_input--standard">{props.data[6].hcp}</td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p1h6" 
                                                value={input.p1h6}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p2h6" 
                                                value={input.p2h6}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p3h6" 
                                                value={input.p3h6}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p4h6" 
                                                value={input.p4h6}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="scorecard_input--hole">7</td>
                                        <td className="scorecard_input--white">{props.data[7].gp}</td>
                                        <td className="scorecard_input--yellow">{props.data[7].g}</td>
                                        <td className="scorecard_input--blue">{props.data[7].lp}</td>
                                        <td className="scorecard_input--pink">{props.data[7].l}</td>
                                        <td className="scorecard_input--standard">{props.data[7].par}</td>
                                        <td className="scorecard_input--standard">{props.data[7].hcp}</td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p1h7" 
                                                value={input.p1h7}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p2h7" 
                                                value={input.p2h7}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p3h7" 
                                                value={input.p3h7}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p4h7" 
                                                value={input.p4h7}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="scorecard_input--hole">8</td>
                                        <td className="scorecard_input--white">{props.data[8].gp}</td>
                                        <td className="scorecard_input--yellow">{props.data[8].g}</td>
                                        <td className="scorecard_input--blue">{props.data[8].lp}</td>
                                        <td className="scorecard_input--pink">{props.data[8].l}</td>
                                        <td className="scorecard_input--standard">{props.data[8].par}</td>
                                        <td className="scorecard_input--standard">{props.data[8].hcp}</td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p1h8" 
                                                value={input.p1h8}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p2h8" 
                                                value={input.p2h8}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p3h8" 
                                                value={input.p3h8}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p4h8" 
                                                value={input.p4h8}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="scorecard_input--hole">9</td>
                                        <td className="scorecard_input--white">{props.data[9].gp}</td>
                                        <td className="scorecard_input--yellow">{props.data[9].g}</td>
                                        <td className="scorecard_input--blue">{props.data[9].lp}</td>
                                        <td className="scorecard_input--pink">{props.data[9].l}</td>
                                        <td className="scorecard_input--standard">{props.data[9].par}</td>
                                        <td className="scorecard_input--standard">{props.data[9].hcp}</td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p1h9" 
                                                value={input.p1h9}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p2h9" 
                                                value={input.p2h9}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p3h9" 
                                                value={input.p3h9}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p4h9" 
                                                value={input.p4h9}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="scorecard_input--hole">10</td>
                                        <td className="scorecard_input--white">{props.data[10].gp}</td>
                                        <td className="scorecard_input--yellow">{props.data[10].g}</td>
                                        <td className="scorecard_input--blue">{props.data[10].lp}</td>
                                        <td className="scorecard_input--pink">{props.data[10].l}</td>
                                        <td className="scorecard_input--standard">{props.data[10].par}</td>
                                        <td className="scorecard_input--standard">{props.data[10].hcp}</td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p1h10" 
                                                value={input.p1h10}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p2h10" 
                                                value={input.p2h10}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p3h10" 
                                                value={input.p3h10}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p4h10" 
                                                value={input.p4h10}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="scorecard_input--hole">11</td>
                                        <td className="scorecard_input--white">{props.data[11].gp}</td>
                                        <td className="scorecard_input--yellow">{props.data[11].g}</td>
                                        <td className="scorecard_input--blue">{props.data[11].lp}</td>
                                        <td className="scorecard_input--pink">{props.data[11].l}</td>
                                        <td className="scorecard_input--standard">{props.data[11].par}</td>
                                        <td className="scorecard_input--standard">{props.data[11].hcp}</td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p1h11" 
                                                value={input.p1h11}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p2h11" 
                                                value={input.p2h11}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p3h11" 
                                                value={input.p3h11}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p4h11" 
                                                value={input.p4h11}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="scorecard_input--hole">12</td>
                                        <td className="scorecard_input--white">{props.data[12].gp}</td>
                                        <td className="scorecard_input--yellow">{props.data[12].g}</td>
                                        <td className="scorecard_input--blue">{props.data[12].lp}</td>
                                        <td className="scorecard_input--pink">{props.data[12].l}</td>
                                        <td className="scorecard_input--standard">{props.data[12].par}</td>
                                        <td className="scorecard_input--standard">{props.data[12].hcp}</td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p1h12" 
                                                value={input.p1h12}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p2h12" 
                                                value={input.p2h12}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p3h12" 
                                                value={input.p3h12}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p4h12" 
                                                value={input.p4h12}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="scorecard_input--hole">13</td>
                                        <td className="scorecard_input--white">{props.data[13].gp}</td>
                                        <td className="scorecard_input--yellow">{props.data[13].g}</td>
                                        <td className="scorecard_input--blue">{props.data[13].lp}</td>
                                        <td className="scorecard_input--pink">{props.data[13].l}</td>
                                        <td className="scorecard_input--standard">{props.data[13].par}</td>
                                        <td className="scorecard_input--standard">{props.data[13].hcp}</td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p1h13" 
                                                value={input.p1h13}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p2h13" 
                                                value={input.p2h13}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p3h13" 
                                                value={input.p3h13}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p4h13" 
                                                value={input.p4h13}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="scorecard_input--hole">14</td>
                                        <td className="scorecard_input--white">{props.data[14].gp}</td>
                                        <td className="scorecard_input--yellow">{props.data[14].g}</td>
                                        <td className="scorecard_input--blue">{props.data[14].lp}</td>
                                        <td className="scorecard_input--pink">{props.data[14].l}</td>
                                        <td className="scorecard_input--standard">{props.data[14].par}</td>
                                        <td className="scorecard_input--standard">{props.data[14].hcp}</td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p1h14" 
                                                value={input.p1h14}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p2h14" 
                                                value={input.p2h14}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p3h14" 
                                                value={input.p3h14}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p4h14" 
                                                value={input.p4h14}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="scorecard_input--hole">15</td>
                                        <td className="scorecard_input--white">{props.data[15].gp}</td>
                                        <td className="scorecard_input--yellow">{props.data[15].g}</td>
                                        <td className="scorecard_input--blue">{props.data[15].lp}</td>
                                        <td className="scorecard_input--pink">{props.data[15].l}</td>
                                        <td className="scorecard_input--standard">{props.data[15].par}</td>
                                        <td className="scorecard_input--standard">{props.data[15].hcp}</td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p1h15" 
                                                value={input.p1h15}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p2h15" 
                                                value={input.p2h15}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p3h15" 
                                                value={input.p3h15}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p4h15" 
                                                value={input.p4h15}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="scorecard_input--hole">16</td>
                                        <td className="scorecard_input--white">{props.data[16].gp}</td>
                                        <td className="scorecard_input--yellow">{props.data[16].g}</td>
                                        <td className="scorecard_input--blue">{props.data[16].lp}</td>
                                        <td className="scorecard_input--pink">{props.data[16].l}</td>
                                        <td className="scorecard_input--standard">{props.data[16].par}</td>
                                        <td className="scorecard_input--standard">{props.data[16].hcp}</td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p1h16" 
                                                value={input.p1h16}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p2h16" 
                                                value={input.p2h16}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p3h16" 
                                                value={input.p3h16}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p4h16" 
                                                value={input.p4h16}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="scorecard_input--hole">17</td>
                                        <td className="scorecard_input--white">{props.data[17].gp}</td>
                                        <td className="scorecard_input--yellow">{props.data[17].g}</td>
                                        <td className="scorecard_input--blue">{props.data[17].lp}</td>
                                        <td className="scorecard_input--pink">{props.data[17].l}</td>
                                        <td className="scorecard_input--standard">{props.data[17].par}</td>
                                        <td className="scorecard_input--standard">{props.data[17].hcp}</td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p1h17" 
                                                value={input.p1h17}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p2h17" 
                                                value={input.p2h17}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p3h17" 
                                                value={input.p3h17}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p4h17" 
                                                value={input.p4h17}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="scorecard_input--hole">18</td>
                                        <td className="scorecard_input--white">{props.data[18].gp}</td>
                                        <td className="scorecard_input--yellow">{props.data[18].g}</td>
                                        <td className="scorecard_input--blue">{props.data[18].lp}</td>
                                        <td className="scorecard_input--pink">{props.data[18].l}</td>
                                        <td className="scorecard_input--standard">{props.data[18].par}</td>
                                        <td className="scorecard_input--standard">{props.data[18].hcp}</td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p1h18" 
                                                value={input.p1h18}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p2h18" 
                                                value={input.p2h18}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p3h18" 
                                                value={input.p3h18}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                        <td className="scorecard_input--player">
                                            <input 
                                                className='edit_hole'
                                                name= "p4h18" 
                                                value={input.p4h18}
                                                onChange={handleInput}
                                                type= "number" 
                                                min="0"
                                                placeholder="+">
                                            </input>
                                        </td>
                                    </tr>   
                                </tbody>
                                <tfoot>
                                    <tr>
                                    <td className="results_standard">Total</td>
                                        <td className="results_starts">{props.data[19].totalGp}</td>
                                        <td className="results_starts">{props.data[19].totalG}</td>
                                        <td className="results_starts">{props.data[19].totalLp}</td>
                                        <td className="results_starts">{props.data[19].totalL}</td>
                                        <td className="results_standard">{props.data[19].totalPar}</td>
                                        <td className="results_standard">-</td>
                                        <td className="results_player">{result}</td>
                                        <td className="results_player">{result2}</td>
                                        <td className="results_player">{result3}</td>
                                        <td className="results_player">{result4}</td>
                                    </tr>
                                </tfoot>      
                    </table>
                    <p className='legend-scorecard' >Ha ahorrado <b>150g de CO2</b>. Fecha: {date}. Tecnología por <b>Mascaddie</b>.  </p>                    </div>
                    <button className="button" onClick={downloadHook}>Guarde su tarjeta de puntuación</button>
            </div>
    )
};

export default CourseScorecardp4;