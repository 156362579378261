import React from 'react';

// Importación estilos
import '../assets/styles/loader.css';

// Importación GIF
import LoaderGif from '../assets/statics/gif/loader.gif';

const Loader = () => {
    return( 
        <div className="loader">
            <img className="loader-img" src={LoaderGif} alt="loader gif" />
            <div className="loader-text">Cargando tarjeta digital...</div>
        </div>
    )
};
export default Loader;