// Importación imágenes
import Logo from '../assets/statics/club_logos/t_golf_logo.png'

export const courseData = {
    1: {
        gp: 401,
        g: 379,
        lp: 352,
        l: 325,
        par: 4,
        hcp: 17
    },
    2: {
        gp: 557,
        g: 513,
        lp: 482,
        l: 425,
        par: 5,
        hcp: 7
    },
    3: {
        gp: 397,
        g: 376,
        lp: 349,
        l: 338,
        par: 4,
        hcp: 1
    },
    4: {
        gp: 365,
        g: 355,
        lp: 337,
        l: 314,
        par: 4,
        hcp: 5
    },
    5: {
        gp: 438,
        g: 413,
        lp: 385,
        l: 354,
        par: 5,
        hcp: 13
    },
    6: {
        gp: 172,
        g: 151,
        lp: 130,
        l: 114,
        par: 3,
        hcp: 15
    },
    7: {
        gp: 325,
        g: 299,
        lp: 270,
        l: 230,
        par: 4,
        hcp: 3
    },
    8: {
        gp: 373,
        g: 350,
        lp: 324,
        l: 308,
        par: 4,
        hcp: 9
    },
    9: {
        gp: 184,
        g: 175,
        lp: 156,
        l: 142,
        par: 3,
        hcp: 11
    },
    10: {
        gp: 432,
        g: 403,
        lp: 366,
        l: 340,
        par: 4,
        hcp: 2
    },
    11: {
        gp: 508,
        g: 488,
        lp: 468,
        l: 448,
        par: 5,
        hcp: "-"
    },
    12: {
        gp: 209,
        g: 194,
        lp: 146,
        l: 135,
        par: 3,
        hcp: 10
    },
    13: {
        gp: 371,
        g: 364,
        lp: 331,
        l: 312,
        par: 4,
        hcp: 14
    },
    14: {
        gp: 334,
        g: 320,
        lp: 300,
        l: 280,
        par: 4,
        hcp: 16
    },
    15: {
        gp: 154,
        g: 137,
        lp: 100,
        l: 85,
        par: 3,
        hcp: 18
    },
    16: { 
        gp: 347,
        g: 323,
        lp: 303,
        l: 285,
        par: 4,
        hcp: 4
    },
    17: {
        gp: 375,
        g: 358,
        lp: 338,
        l: 318,
        par: 4,
        hcp: 8
    },
    18: {
        gp: 510,
        g: 482,
        lp: 463,
        l: 402,
        par: 5,
        hcp: 12
    },
    19:{
        totalGp: 6452,
        totalG: 6080,
        totalLp: 5600,
        totalL: 5155,
        totalPar: "72 (36)",
    },
    20:{
        title: "T Golf & Country Club",
        image: Logo
    }
}