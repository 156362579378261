// Importación imágenes
import Logo from '../assets/statics/club_logos/golf_santa_ponsa_logo.png'

export const courseData = {
    1: {
        gp: "-",
        g: 254,
        lp: "-",
        l: 231,
        par: 4,
        hcp: 1
    },
    2: {
        gp: "-",
        g: 271,
        lp: "-",
        l: 232,
        par: 4,
        hcp: 5
    },
    3: {
        gp: "-",
        g: 98,
        lp: "-",
        l: 84,
        par: 3,
        hcp: 9
    },
    4: {
        gp: "-",
        g: 115,
        lp: "-",
        l: 100,
        par: 3,
        hcp: 8
    },
    5: {
        gp: "-",
        g: 120,
        lp: "-",
        l: 84,
        par: 3,
        hcp: 7
    },
    6: {
        gp: "-",
        g: 304,
        lp: "-",
        l: 254,
        par: 4,
        hcp: 2
    },
    7: {
        gp: "-",
        g: 163,
        lp: "-",
        l: 125,
        par: 4,
        hcp: 2
    },
    8: {
        gp: "-",
        g: 119,
        lp: "-",
        l: 104,
        par: 3,
        hcp: 6
    },
    9: {
        gp: "-",
        g: 155,
        lp: "-",
        l: 118,
        par: 3,
        hcp: 3
    },
    10:{
        totalGp: "-",
        totalG: 1599,
        totalLp: "-",
        totalL: 1332,
        totalPar: 30,
    },
    11:{
        title: "Golf Santa Ponsa III",
        image: Logo
    }
}