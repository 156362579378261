import React from "react";

//import specific player number scorecard
import CourseScorecard9 from "../components/CourseScorecard_9";
import CourseScorecard9p2 from "../components/CourseScorecard_9_2p";
import CourseScorecard9p3 from "../components/CourseScorecard_9_3p";
import CourseScorecard9p4 from "../components/CourseScorecard_9_4p";


//import data from courses
import {courseData} from "../data/02_dataPalmaPitchAndPutt.js"

const SCPalmaPitchAndPutt = () => { 

    const totalPlayers = Number(sessionStorage.getItem('playersNumber'));
 
    const renderScorecard = (e) => {
        switch (totalPlayers) {
            case 2:
                return < CourseScorecard9p2 data = {e}/>
            case 3:
                return < CourseScorecard9p3 data = {e}/>
            case 4:
                return < CourseScorecard9p4 data = {e}/>
            default:
                return < CourseScorecard9 data = {e}/>
        }
    }
    return ( 
        <div>                    
            {renderScorecard(courseData)}
        </div>
    )
}

export default SCPalmaPitchAndPutt;