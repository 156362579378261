// Importación imágenes
import Logo from '../assets/statics/club_logos/son_quint_logo.svg'

export const courseData = {
    1: {
        gp: 351,
        g: 334,
        lp: 307,
        l: 284,
        par: 4,
        hcp: 9
    },
    2: {
        gp: 360,
        g: 347,
        lp: 316,
        l: 293,
        par: 4,
        hcp: 4
    },
    3: {
        gp: 165,
        g: 160,
        lp: 151,
        l: 139,
        par: 3,
        hcp: 17
    },
    4: {
        gp: 372,
        g: 356,
        lp: 333,
        l: 319,
        par: 4,
        hcp: 8
    },
    5: {
        gp: 338,
        g: 328,
        lp: 318,
        l: 308,
        par: 4,
        hcp: 11
    },
    6: {
        gp: 326,
        g: 313,
        lp: 290,
        l: 263,
        par: 4,
        hcp: 3
    },
    7: {
        gp: 446,
        g: 436,
        lp: 410,
        l: 390,
        par: 5,
        hcp: 12
    },
    8: {
        gp: 362,
        g: "-",
        lp: 330,
        l: 300,
        par: 4,
        hcp: 13
    },
    9: {
        gp: 157,
        g: 139,
        lp: 131,
        l: 121,
        par: 3,
        hcp: 14
    },
    10: {
        gp: 340,
        g: 316,
        lp: 290,
        l: 268,
        par: 4,
        hcp: 16
    },
    11: {
        gp: 346,
        g: 334,
        lp: 303,
        l: 288,
        par: 4,
        hcp: 6
    },
    12: {
        gp: 195,
        g: 177,
        lp: 166,
        l: 151,
        par: 3,
        hcp: 5
    },
    13: {
        gp: 476,
        g: 452,
        lp: 423,
        l: 402,
        par: 5,
        hcp: 1
    },
    14: {
        gp: 120,
        g: 117,
        lp: 113,
        l: 106,
        par: 3,
        hcp: 18
    },
    15: {
        gp: 375,
        g: 351,
        lp: 324,
        l: 298,
        par: 4,
        hcp: 15
    },
    16: {
        gp: 396,
        g: 383,
        lp: 339,
        l: 326,
        par: 4,
        hcp: 2
    },
    17: {
        gp: 501,
        g: 483,
        lp: 435,
        l: 418,
        par: 5,
        hcp: 10
    },
    18: {
        gp: 396,
        g: 374,
        lp: 346,
        l: 313,
        par: 4,
        hcp: 7
    },
    19:{
        totalGp: 6021,
        totalG: 5750,
        totalLp: 5325,
        totalL: 4987,
        totalPar: "71 (35)",
    },
    20:{
        title: "Son Quint Golf Club",
        image: Logo
    }
}