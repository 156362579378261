import React from "react";

//import specific player number scorecard
import CourseScorecard from "../components/CourseScorecard";
import CourseScorecardp2 from "../components/CourseScorecard_2p";
import CourseScorecardp3 from "../components/CourseScorecard_3p";
import CourseScorecardp4 from "../components/CourseScorecard_4p";

//import data from courses
import {courseData} from "../data/08_dataTGolfClub"

const SCTGolfClub = () => { 
    const totalPlayers = Number(sessionStorage.getItem('playersNumber'));
    const renderScorecard = (e) => {
        switch (totalPlayers) {
            case 2:
                return < CourseScorecardp2 data = {e}/>
            case 3:
                return < CourseScorecardp3 data = {e}/>
            case 4:
                return < CourseScorecardp4 data = {e}/>
            default:
                return < CourseScorecard data = {e}/>
        }
    }
    return ( 
        <div>                    
            {renderScorecard(courseData)}
        </div>
    )
}

export default SCTGolfClub;