// Importación imágenes
import Logo from '../assets/statics/club_logos/palma_pitch_and_putt_logo.svg'

export const courseData = {
    1: {
        gp: "-",
        g: 63,
        lp: "-",
        l: "-",
        par: 3,
        hcp: 17
    },
    2: {
        gp: "-",
        g: 57,
        lp: "-",
        l: "-",
        par: 3,
        hcp: 13
    },
    3: {
        gp: "-",
        g: 59,
        lp: "-",
        l: "-",
        par: 3,
        hcp: 11
    },
    4: {
        gp: "-",
        g: 96,
        lp: "-",
        l: "-",
        par: 3,
        hcp: 5
    },
    5: {
        gp: "-",
        g: 67,
        lp: "-",
        l: "-",
        par: 3,
        hcp: 9
    },
    6: {
        gp: "-",
        g: 65,
        lp: "-",
        l: "-",
        par: 3,
        hcp: 15
    },
    7: {
        gp: "-",
        g: 64,
        lp: "-",
        l: "-",
        par: 3,
        hcp: 1
    },
    8: {
        gp: "-",
        g: 83,
        lp: "-",
        l: "-",
        par: 3,
        hcp: 7
    },
    9: {
        gp: "-",
        g: 84,
        lp: "-",
        l: "-",
        par: 3,
        hcp: 3
    },
    10:{
        totalGp: "-",
        totalG: 638,
        totalLp: "-",
        totalL: "-",
        totalPar: 27,
    },
    11:{
        title: "Palma Pitch & Putt",
        image: Logo
    }
}