// Importación imágenes
import Logo from '../assets/statics/club_logos/son_antem_logo.png'

export const courseData = {
    1: {
        gp: 364,
        g: 338,
        lp: 295,
        l: 275,
        par: 4,
        hcp: 12
    },
    2: {
        gp: 186,
        g: 178,
        lp: 146,
        l: 133,
        par: 3,
        hcp: 16
    },
    3: {
        gp: 482,
        g: 449,
        lp: 412,
        l: 371,
        par: 5,
        hcp: 10
    },
    4: {
        gp: 381,
        g: 361,
        lp: 347,
        l: 314,
        par: 4,
        hcp: 4
    },
    5: {
        gp: 377,
        g: 351,
        lp: 317,
        l: 299,
        par: 4,
        hcp: 8
    },
    6: {
        gp: 297,
        g: 270,
        lp: 246,
        l: 226,
        par: 4,
        hcp: 14
    },
    7: {
        gp: 483,
        g: 454,
        lp: 422,
        l: 395,
        par: 5,
        hcp: 6
    },
    8: {
        gp: 139,
        g: 131,
        lp: 110,
        l: 100,
        par: 3,
        hcp: 18
    },
    9: {
        gp: 357,
        g: 340,
        lp: 312,
        l: 301,
        par: 4,
        hcp: 2
    },
    10: {
        gp: 393,
        g: 379,
        lp: 332,
        l: 314,
        par: 4,
        hcp: 7
    },
    11: {
        gp: 153,
        g: 139,
        lp: 109,
        l: 95,
        par: 3,
        hcp: 17
    },
    12: {
        gp: 367,
        g: 342,
        lp: 321,
        l: 296,
        par: 4,
        hcp: 9
    },
    13: {
        gp: 471,
        g: 444,
        lp: 403,
        l: 380,
        par: 5,
        hcp: 13
    },
    14: {
        gp: 374,
        g: 356,
        lp: 325,
        l: 295,
        par: 4,
        hcp: 5
    },
    15: {
        gp: 384,
        g: 364,
        lp: 334,
        l: 314,
        par: 4,
        hcp: 11
    },
    16: { 
        gp: 495,
        g: 465,
        lp: 448,
        l: 425,
        par: 5,
        hcp: 3
    },
    17: {
        gp: 190,
        g: 182,
        lp: 164,
        l: 148,
        par: 3,
        hcp: 15
    },
    18: {
        gp: 400,
        g: 370,
        lp: 353,
        l: 326,
        par: 4,
        hcp: 1
    },
    19:{
        totalGp: 6293,
        totalG: 5913,
        totalLp: 5396,
        totalL: 5007,
        totalPar: "72 (36)",
    },
    20:{
        title: "Golf Son Antem | West",
        image: Logo
    }
}