// Importación imágenes
import Logo from '../assets/statics/club_logos/son_muntaner_logo.svg'

export const courseData = {
    1: {
        gp: 372,
        g: 351,
        lp: 324,
        l: 291,
        par: 4,
        hcp: 13
    },
    2: {
        gp: 487,
        g: 478,
        lp: 444,
        l: 418,
        par: 5,
        hcp: 3
    },
    3: {
        gp: 350,
        g: 324,
        lp: 308,
        l: 292,
        par: 4,
        hcp: 15
    },
    4: {
        gp: 407,
        g: 377,
        lp: 333,
        l: 305,
        par: 4,
        hcp: 7
    },
    5: {
        gp: 196,
        g: 177,
        lp: 150,
        l: 270,
        par: 3,
        hcp: 11
    },
    6: {
        gp: 416,
        g: 381,
        lp: 357,
        l: 312,
        par: 4,
        hcp: 5
    },
    7: {
        gp: 141,
        g: 130,
        lp: 126,
        l: 122,
        par: 3,
        hcp: 17
    },
    8: {
        gp: 502,
        g: 488,
        lp: 419,
        l: 410,
        par: 5,
        hcp: 1
    },
    9: {
        gp: 388,
        g: 362,
        lp: 338,
        l: 311,
        par: 4,
        hcp: 9
    },
    10: {
        gp: 382,
        g: 365,
        lp: 323,
        l: 311,
        par: 4,
        hcp: 4
    },
    11: {
        gp: 205,
        g: 193,
        lp: 172,
        l: 153,
        par: 3,
        hcp: 12
    },
    12: {
        gp: 359,
        g: 348,
        lp: 301,
        l: 291,
        par: 4,
        hcp: 8
    },
    13: {
        gp: 130,
        g: 120,
        lp: 101,
        l: 94,
        par: 3,
        hcp: 18
    },
    14: {
        gp: 315,
        g: 292,
        lp: 282,
        l: 253,
        par: 4,
        hcp: 14
    },
    15: {
        gp: 524,
        g: 515,
        lp: 459,
        l: 435,
        par: 5,
        hcp: 2
    },
    16: {
        gp: 442,
        g: 429,
        lp: 379,
        l: 365,
        par: 5,
        hcp: 16
    },
    17: {
        gp: 328,
        g: 304,
        lp: 285,
        l: 275,
        par: 4,
        hcp: 10
    },
    18: {
        gp: 361,
        g: 351,
        lp: 319,
        l: 310,
        par: 4,
        hcp: 6
    },
    19:{
        totalGp: 6305,
        totalG: 5985,
        totalLp: 5430,
        totalL: 5098,
        totalPar: "72 (36)",
    },
    20:{
        title: "Son Muntaner Golf Club",
        image: Logo
    }
}