import React from 'react';
import {useNavigate} from 'react-router-dom';

// Importación estilos
import '../assets/styles/popup.css';

// Importación imágenes
import AddPlayer from '../assets/statics/others/access_ep.png'
import Random from '../assets/statics/others/access_kc.png'

const HeaderScoreCard = () => {
    const navigate = useNavigate();

    // Para editar el nº de players
    const handleAddPlayer = function (e) {
        setTimeout(() => navigate('/editplayers'), 200) 
    };
    // Para editar el nº de players
    const handleNewKingCard = function (e) {
        setTimeout(() => navigate('/kingsplay'), 200) 
    };
    
    return( 
        <div className='edit-player'> 
        <img className='edit-player__img' src={AddPlayer} alt='edit_player_button' onClick={handleAddPlayer} />
        <img className='edit-player__img' src={Random} alt='new_king_card' onClick={handleNewKingCard} />
    </div>
    )
};

export default HeaderScoreCard;