// Importación imágenes
import Logo from '../assets/statics/club_logos/son_vida_logo.svg'

export const courseData = {
    1: {
        gp: "-",
        g: 258,
        lp: "-",
        l: 237,
        par: 4,
        hcp: 13
    },
    2: {
        gp: "-",
        g: 191,
        lp: "-",
        l: 158,
        par: 3,
        hcp: 3
    },
    3: {
        gp: "-",
        g: 332,
        lp: "-",
        l: 295,
        par: 4,
        hcp: 1
    },
    4: {
        gp: "-",
        g: 139,
        lp: "-",
        l: 110,
        par: 3,
        hcp: 15
    },
    5: {
        gp: "-",
        g: 362,
        lp: "-",
        l: 332,
        par: 4,
        hcp: 5
    },
    6: {
        gp: "-",
        g: 449,
        lp: "-",
        l: 404,
        par: 5,
        hcp: 7
    },
    7: {
        gp: "-",
        g: 298,
        lp: "-",
        l: 230,
        par: 4,
        hcp: 9
    },
    8: {
        gp: "-",
        g: 132,
        lp: "-",
        l: 107,
        par: 3,
        hcp: 17
    },
    9: {
        gp: "-",
        g: 309,
        lp: "-",
        l: 256,
        par: 4,
        hcp: 11
    },
    10: {
        gp: "-",
        g: 267,
        lp: "-",
        l: 244,
        par: 4,
        hcp: 14
    },
    11: {
        gp: "-",
        g: 473,
        lp: "-",
        l: 389,
        par: 5,
        hcp: 2
    },
    12: {
        gp: "-",
        g: 137,
        lp: "-",
        l: 109,
        par: 3,
        hcp: 16
    },
    13: {
        gp: "-",
        g: 502,
        lp: "-",
        l: 412,
        par: 5,
        hcp: 12
    },
    14: {
        gp: "-",
        g: 327,
        lp: "-",
        l: 296,
        par: 4,
        hcp: 10
    },
    15: {
        gp: "-",
        g: 368,
        lp: "-",
        l: 302,
        par: 4,
        hcp: 6
    },
    16: { // aqui abajo
        gp: "-",
        g: 125,
        lp: "-",
        l: 100,
        par: 3,
        hcp: 18
    },
    17: {
        gp: "-",
        g: 400,
        lp: "-",
        l: 134,
        par: 4,
        hcp: 4
    },
    18: {
        gp: "-",
        g: 460,
        lp: "-",
        l: 414,
        par: 5,
        hcp: 8
    },
    19:{
        totalGp: "-",
        totalG: 5529,
        totalLp: "-",
        totalL: 4709,
        totalPar: "71 (34)",
    },
    20:{
        title: "Son Vida Golf Club | Green",
        image: Logo,
    }
}