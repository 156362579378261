// Importación imágenes
import Logo from '../assets/statics/club_logos/canyamel_golf_logo.png'

export const courseData = {
    1: {
        gp: "-",
        g: 325,
        lp: "-",
        l: 280,
        par: 4,
        hcp: 16
    },
    2: {
        gp: "-",
        g: 350,
        lp: "-",
        l: 327,
        par: 4,
        hcp: 2
    },
    3: {
        gp: "-",
        g: 313,
        lp: "-",
        l: 287,
        par: 4,
        hcp: 11
    },
    4: {
        gp: "-",
        g: 448,
        lp: "-",
        l: 414,
        par: 5,
        hcp: 5
    },
    5: {
        gp: "-",
        g: 93,
        lp: "-",
        l: 75,
        par: 3,
        hcp: 18
    },
    6: {
        gp: "-",
        g: 319,
        lp: "-",
        l: 272,
        par: 4,
        hcp: 13
    },
    7: {
        gp: "-",
        g: 477,
        lp: "-",
        l: 449,
        par: 5,
        hcp: 9
    },
    8: {
        gp: "-",
        g: 189,
        lp: "-",
        l: 145,
        par: 3,
        hcp: 7
    },
    9: {
        gp: "-",
        g: 384,
        lp: "-",
        l: 287,
        par: 4,
        hcp: 12
    },
    10: {
        gp: "-",
        g: 348,
        lp: "-",
        l: 287,
        par: 4,
        hcp: 12
    },
    11: {
        gp: "-",
        g: 314,
        lp: "-",
        l: 268,
        par: 4,
        hcp: 17
    },
    12: {
        gp: "-",
        g: 355,
        lp: "-",
        l: 321,
        par: 4,
        hcp: 6
    },
    13: {
        gp: "-",
        g: 450,
        lp: "-",
        l: 422,
        par: 5,
        hcp: 4
    },
    14: {
        gp: "-",
        g: 128,
        lp: "-",
        l: 104,
        par: 3,
        hcp: 14
    },
    15: {
        gp: "-",
        g: 483,
        lp: "-",
        l: 433,
        par: 5,
        hcp: 3
    },
    16: { 
        gp: "-",
        g: 366,
        lp: "-",
        l: 306,
        par: 4,
        hcp: 10
    },
    17: {
        gp: "-",
        g: 451,
        lp: "-",
        l: 409,
        par: 5,
        hcp: 15
    },
    18: {
        gp: "-",
        g: 160,
        lp: "-",
        l: 137,
        par: 3,
        hcp: 8
    },
    19:{
        totalGp: "-",
        totalG: 5953,
        totalLp: "-",
        totalL: 5303,
        totalPar: "73 (36)",
    },
    20:{
        title: "Golf Canyamel",
        image: Logo
    }
}