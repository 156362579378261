// Importación imágenes
import Logo from '../assets/statics/club_logos/vall_dor_golf_logo.png'

export const courseData = {
    1: {
        gp: 153,
        g: 147,
        lp: 126,
        l: 117,
        par: 3,
        hcp: 7
    },
    2: {
        gp: 319,
        g: 309,
        lp: 276,
        l: 267,
        par: 4,
        hcp: 1
    },
    3: {
        gp: 450,
        g: 439,
        lp: 426,
        l: 402,
        par: 5,
        hcp: 13
    },
    4: {
        gp: 132,
        g: 121,
        lp: 100,
        l: 88,
        par: 3,
        hcp: 15
    },
    5: {
        gp: 314,
        g: 301,
        lp: 282,
        l: 275,
        par: 4,
        hcp: 9
    },
    6: {
        gp: 343,
        g: 333,
        lp: 321,
        l: 291,
        par: 4,
        hcp: 3
    },
    7: {
        gp: 350,
        g: 334,
        lp: 305,
        l: 290,
        par: 4,
        hcp: 5
    },
    8: {
        gp: 301,
        g: 282,
        lp: 262,
        l: 246,
        par: 4,
        hcp: 17
    },
    9: {
        gp: 478,
        g: 462,
        lp: 437,
        l: 417,
        par: 5,
        hcp: 11
    },
    10: {
        gp: 349,
        g: 337,
        lp: 321,
        l: 300,
        par: 4,
        hcp: 4
    },
    11: {
        gp: 417,
        g: 410,
        lp: 399,
        l: 390,
        par: 5,
        hcp: 12
    },
    12: {
        gp: 142,
        g: 134,
        lp: 133,
        l: 119,
        par: 3,
        hcp: 14
    },
    13: {
        gp: 497,
        g: 479,
        lp: 470,
        l: 443,
        par: 5,
        hcp: 10
    },
    14: {
        gp: 298,
        g: 292,
        lp: 278,
        l: 277,
        par: 4,
        hcp: 18
    },
    15: {
        gp: 164,
        g: 153,
        lp: 141,
        l: 130,
        par: 3,
        hcp: 16
    },
    16: { 
        gp: 342,
        g: 333,
        lp: 309,
        l: 275,
        par: 4,
        hcp: 2
    },
    17: {
        gp: 325,
        g: 313,
        lp: 292,
        l: 279,
        par: 4,
        hcp: 8
    },
    18: {
        gp: 165,
        g: 156,
        lp: 146,
        l: 136,
        par: 3,
        hcp: 6
    },
    19:{
        totalGp: 5538,
        totalG: 5335,
        totalLp: 5024,
        totalL: 4742,
        totalPar: "71 (35)",
    },
    20:{
        title: "Vall d'Or Golf Club ",
        image: Logo
    }
}